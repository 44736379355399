/**
 * Feature Flags Helper
 * @description Manages feature flags for the application
 */

// Environment variables
export const USE_FEATURES_V2 = import.meta.env.VITE_USE_FEATURES_V2 === 'true';

// Feature specific flags
export const FEATURES_V2 = {
  EVENTS: import.meta.env.VITE_USE_EVENTS_V2 === 'true' || USE_FEATURES_V2,
  ECOMMERCE: import.meta.env.VITE_USE_ECOMMERCE_V2 === 'true' || USE_FEATURES_V2,
};

/**
 * Get component version based on feature flag
 * @param {Object} versions - Object containing V1 and V2 components
 * @param {React.ComponentType} versions.V1 - Original version of the component
 * @param {React.ComponentType} versions.V2 - New version of the component
 * @param {string} feature - Feature name to check flag for
 * @returns {React.ComponentType} - Component version to use
 */
export const getVersionedComponent = ({ V1, V2 }, feature) => {


  if (!V2) {
    console.log('V2 component not available, falling back to V1');
    return V1;
  }
  
  const Component = FEATURES_V2[feature] ? V2 : V1;
  console.log('Using component:', FEATURES_V2[feature] ? 'V2' : 'V1');
  return Component;
};
