import { useMemo, useState } from 'react';

export const useTablePagination = (data, pageSize) => {
  const [currentPage, setCurrentPage] = useState(1);

  const paginatedData = useMemo(() => {
    const start = (currentPage - 1) * pageSize;
    return data.slice(start, start + pageSize);
  }, [data, currentPage, pageSize]);

  const totalPages = Math.ceil(data.length / pageSize);

  return {
    currentPage,
    setCurrentPage,
    paginatedData,
    totalPages
  };
}; 