import React from 'react';
import PropTypes from 'prop-types';
import {
  LineChart as RechartsLineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import ChartContainer from '../../base/ChartContainer';

const LineChart = ({
  data,
  lines,
  title,
  asymRadius,
  reverse,
  radius,
  height,
  xAxisDataKey = 'name',
  grid = true,
}) => {
  return (
    <ChartContainer
      title={title}
      asymRadius={asymRadius}
      reverse={reverse}
      radius={radius}
      height={height}
    >
      <ResponsiveContainer width="100%" height="100%">
        <RechartsLineChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          {grid && (
            <CartesianGrid 
              strokeDasharray="3 3" 
              stroke="rgba(255, 255, 255, 0.1)"
            />
          )}
          <XAxis 
            dataKey={xAxisDataKey} 
            stroke="rgba(255, 255, 255, 0.5)"
          />
          <YAxis 
            stroke="rgba(255, 255, 255, 0.5)"
          />
          <Tooltip 
            contentStyle={{
              background: 'rgba(42, 45, 58, 0.9)',
              border: '1px solid rgba(255, 255, 255, 0.1)',
              borderRadius: '8px',
              color: 'white'
            }}
          />
          {lines.map((line, index) => (
            <Line
              key={line.dataKey}
              type="monotone"
              dataKey={line.dataKey}
              stroke={line.color || `var(--${index === 0 ? 'accent' : 'success'}-colour)`}
              strokeWidth={2}
              dot={{ r: 4 }}
              activeDot={{ r: 6 }}
            />
          ))}
        </RechartsLineChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
};

LineChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  lines: PropTypes.arrayOf(PropTypes.shape({
    dataKey: PropTypes.string.isRequired,
    color: PropTypes.string
  })).isRequired,
  title: PropTypes.string,
  asymRadius: PropTypes.string,
  reverse: PropTypes.bool,
  radius: PropTypes.string,
  height: PropTypes.number,
  xAxisDataKey: PropTypes.string,
  grid: PropTypes.bool
};

export default LineChart; 