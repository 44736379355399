import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';
import { FaTrash } from 'react-icons/fa';
import Section from '../../../../../atomic/layouts/Section/Section';
import Button from '../../../../../atomic/atoms/Button/Button';
import Heading from '../../../../../atomic/atoms/Heading/Heading';
import Paragraph from '../../../../../atomic/atoms/Paragraph/Paragraph';
import { useBasket } from '../../../basket/context/BasketContext';
import { convertToCurrency } from '../../../../../js/helpers/helpers';
import { extractColorCodeFromCssVariable } from '../../../../../features/themes/js/utils';

const BasketContainer = styled(motion.div)`
  width: 100%;
  background: var(--surface-colour);
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid var(--border-colour);
  color: var(--text-colour);
`;

const BasketHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  flex-direction: column;
  border-bottom: 1px solid var(--border-colour);
  cursor: pointer;
  background: var(--surface-colour);
  transition: background-color 0.2s ease;

  &:hover {
    background: var(--surface-colour-hover);
  }
`;

const BasketContent = styled(motion.div)`
  overflow: hidden;
`;

const BasketItems = styled.div`
//   padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const BasketItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  border-radius: 8px;
//   background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  border: solid 0.5px ${extractColorCodeFromCssVariable('--accent-colour')};

  &:hover {
    background: var(--surface-colour-active);
  }
`;

const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
`;

const FeeSection = styled.div`
  padding: 16px;
  border-top: 1px solid var(--border-colour);
`;

const FeeItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const DeleteButton = styled.button`
  background: none;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s ease;
  margin-left: 16px;

  &:hover {
    color: var(--error-colour);
  }
`;

const variants = {
  open: {
    height: 'auto',
    opacity: 1,
  },
  closed: {
    height: 0,
    opacity: 0,
  },
};

const CheckoutBasket = () => {
  const [isOpen, setIsOpen] = useState(true);
  const { basket, loading, refreshBasket, removeFromBasket } = useBasket();
  
  // Sync with local storage when component mounts or modal opens
  useEffect(() => {
    refreshBasket();
  }, []); // Run once on mount

  // Listen for storage changes in other tabs/windows
  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === 'basket') {
        refreshBasket();
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, [refreshBasket]);

  const toggleBasket = useCallback(() => {
    setIsOpen(prev => !prev);
  }, []);

  const handleRemoveItem = async (itemId) => {
    await removeFromBasket(itemId);
    refreshBasket();
  };

  // Early return if basket is not loaded
  if (!basket || loading) {    
    return null;
  }

  const { orderItems, total } = basket;

  console.log('basket', basket);

  return (

      <BasketContainer>
        <BasketHeader onClick={toggleBasket}>
          <Heading gradient='var(--accent-colour)' level={3} weight="semibold">Order Summary</Heading>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Paragraph weight="bold" color="var(--text-colour)">{convertToCurrency(basket?.total)}</Paragraph>
            {isOpen ? <IoChevronUp /> : <IoChevronDown />}
          </div>
        </BasketHeader>

        <AnimatePresence initial={false}>
          {isOpen && (
            <BasketContent
              initial="closed"
              animate="open"
              exit="closed"
              variants={variants}
              transition={{ duration: 0.3, ease: 'easeInOut' }}
            >
              <BasketItems name='basket-items'>
                {orderItems?.map((item, index) => (
                  <BasketItem key={item.id || index} name='basket-item'>
                    <ItemInfo name='basket-item-info'>
                      <Paragraph weight="medium">{item.name}</Paragraph>
                      <Paragraph size="sm" color="var(--text-secondary)">
                        Quantity: {item.quantity}
                      </Paragraph>
                    </ItemInfo>
                    <Paragraph weight="medium">{convertToCurrency(item.total)}</Paragraph>
                    <DeleteButton 
                      onClick={() => handleRemoveItem(item.id)}
                      aria-label="Remove item"
                    >
                      <FaTrash size={14} />
                    </DeleteButton>
                  </BasketItem>
                ))}
              </BasketItems>

              {basket.fees?.length > 0 && (
                <FeeSection>
                  {basket.fees.map((fee, index) => (
                    <FeeItem key={fee.id || index}>
                      <Paragraph size="sm" color="var(--text-secondary)">{fee.name}</Paragraph>
                      <Paragraph size="sm" weight="medium">{convertToCurrency(fee.amount)}</Paragraph>
                    </FeeItem>
                  ))}
                </FeeSection>
              )}
            </BasketContent>
          )}
        </AnimatePresence>
      </BasketContainer>
  );
};

export default CheckoutBasket; 