import applications from '../applications';

// Helper function to get application config
export const getApplicationConfig = (applicationId) => {
  const config = applications[applicationId];
  if (!config) {
    console.warn(`Application configuration not found for ID: ${applicationId}`);
    return null;
  }
  return config;
}; 