import React from 'react';
import PropTypes from 'prop-types';
import { SearchContainer, SearchField, SearchLabel } from '../styles/TableStyles';
import { FiSearch } from 'react-icons/fi';
import Input from '../../../../atomic/atoms/form-elements/Input';
import { Select } from '../../../../components/forms';

const TableSearch = ({
  columnOptions = [],
  enumOptions = [],
  columnTypes = {},
  searchColumn = '',
  searchValue = '',
  onColumnChange = () => {},
  onValueChange = () => {},
}) => {
  // Validate inputs
  if (!Array.isArray(columnOptions)) {
    console.warn('TableSearch: columnOptions prop must be an array');
    return null;
  }

  if (!Array.isArray(enumOptions)) {
    console.warn('TableSearch: enumOptions prop must be an array');
    return null;
  }

  if (!columnTypes || typeof columnTypes !== 'object') {
    console.warn('TableSearch: columnTypes prop must be an object');
    return null;
  }

  const selectedColumn = columnOptions.find(c => c.value === searchColumn);
  const placeholder = selectedColumn ? `Search ${selectedColumn.label}` : 'Search';

  return (
    <SearchContainer>
      <SearchField>
        <SearchLabel>Search by</SearchLabel>
        <Select
          options={columnOptions}
          value={columnOptions.find(opt => opt.value === searchColumn)?.value || ''}
          passChangeToParent={onColumnChange}
          placeholder="Select column"
          asymRadius="sm"
        />
      </SearchField>
      
      {searchColumn && (
        <SearchField>
          <SearchLabel>Search value</SearchLabel>
          {columnTypes[searchColumn] === 'enum' ? (
            <Select
              options={enumOptions}
              value={enumOptions.find(opt => opt.value === searchValue)?.value || ''}
              passChangeToParent={onValueChange}
              placeholder={`Select ${selectedColumn?.label || searchColumn}`}
              asymRadius="sm"
            />
          ) : (
            <Input
              value={searchValue}
              onChange={(e) => onValueChange(e?.target?.value ?? '')}
              placeholder={placeholder}
              icon={FiSearch}
              asymRadius="sm"
            />
          )}
        </SearchField>
      )}
    </SearchContainer>
  );
};

TableSearch.propTypes = {
  columnOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  })).isRequired,
  enumOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  })).isRequired,
  columnTypes: PropTypes.object.isRequired,
  searchColumn: PropTypes.string,
  searchValue: PropTypes.string,
  onColumnChange: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
};

export default TableSearch; 