import api from "./api";
import pages from "./pages";
import routes from "./routes";

const modelConfig = {
    name: 'Example Model',
    description: 'Example Model',
    routes: routes,
    api: api,
    pages: pages
};

export default modelConfig;