import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  PieChart as RechartsPieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import styled from 'styled-components';
import ChartContainer from '../../base/ChartContainer';

const COLORS = [
  'var(--accent-colour)',
  'var(--success-colour)',
  'var(--info-colour)',
  'var(--warning-colour)'
];

const RADIAN = Math.PI / 180;

const TooltipContainer = styled.div`
  background: rgba(42, 45, 58, 0.95);
  border: 1px solid var(--border-colour);
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(8px);
`;

const TooltipTitle = styled.div`
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 8px;
  color: ${props => props.$color};
`;

const TooltipRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.8);
  
  & + & {
    margin-top: 4px;
  }

  strong {
    color: white;
    font-weight: 500;
  }
`;

const LegendContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  border-radius: 6px;
  transition: all 0.2s ease;
  cursor: pointer;
  
  &:hover {
    background: rgba(255, 255, 255, 0.05);
  }

  ${props => props.$inactive && `
    opacity: 0.5;
  `}
`;

const LegendDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${props => props.$color};
`;

const LegendLabel = styled.span`
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.8);
`;

const LegendValue = styled.span`
  font-size: 0.875rem;
  color: white;
  margin-left: auto;
  font-weight: 500;
`;

const ChartHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
`;

const LegendWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 12px;
  border-radius: 6px;
  transition: all 0.2s ease;
  cursor: pointer;
  background: ${props => props.$active ? 'rgba(255, 255, 255, 0.05)' : 'transparent'};
  
  &:hover {
    background: rgba(255, 255, 255, 0.05);
  }
`;

// Custom label component
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, value, name }) => {
  const LABEL_OFFSET = 30;
  const radius = outerRadius + LABEL_OFFSET;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const lineX1 = cx + (outerRadius + 5) * Math.cos(-midAngle * RADIAN);
  const lineY1 = cy + (outerRadius + 5) * Math.sin(-midAngle * RADIAN);

  if (percent < 0.05) return null;

  return (
    <g>
      <line
        x1={lineX1}
        y1={lineY1}
        x2={x}
        y2={y}
        stroke="rgba(255, 255, 255, 0.3)"
        strokeWidth={1}
      />
      <circle
        cx={x}
        cy={y}
        r={2}
        fill="rgba(255, 255, 255, 0.5)"
      />
      <text 
        x={x + (x > cx ? 5 : -5)} 
        y={y} 
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'} 
        dominantBaseline="central"
        fontSize="12px"
        fontWeight="500"
      >
        {`${name} (${(percent * 100).toFixed(0)}%)`}
      </text>
    </g>
  );
};

// Custom tooltip component
const CustomTooltip = ({ active, payload }) => {
  if (active && payload?.[0]) {
    const data = payload[0];
    return (
      <TooltipContainer>
        <TooltipTitle $color={data.payload?.color || data.color}>
          {data.name}
        </TooltipTitle>
        <TooltipRow>
          <span>Value</span>
          <strong>{data.value?.toLocaleString()}</strong>
        </TooltipRow>
        <TooltipRow>
          <span>Percentage</span>
          <strong>{((data.value / (data.payload?.total || 1)) * 100).toFixed(1)}%</strong>
        </TooltipRow>
      </TooltipContainer>
    );
  }
  return null;
};

// Custom legend component
const CustomLegend = ({ payload, activeIndex, onMouseEnter, onMouseLeave }) => (
  <div style={{ padding: '0 24px' }}>
    {payload?.map((entry, index) => (
      <LegendContainer 
        key={entry.value}
        onMouseEnter={() => onMouseEnter(index)}
        onMouseLeave={onMouseLeave}
        $inactive={activeIndex !== null && activeIndex !== index}
      >
        <LegendDot $color={entry.color} />
        <LegendLabel>{entry?.value}</LegendLabel>
        <LegendValue>
          {entry?.payload?.value?.toLocaleString()}
        </LegendValue>
      </LegendContainer>
    )) || null}
  </div>
);

const PieChart = ({
  data = [],
  title,
  asymRadius,
  reverse,
  radius,
  height,
  dataKey = 'value',
  nameKey = 'name',
  innerRadius = 0,
  colors = COLORS,
  showLabels = true,
  showLegend = true,
  animate = true,
}) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const total = data?.reduce((sum, item) => sum + (item[dataKey] || 0), 0) || 0;
  const dataWithTotal = data?.map(item => ({ ...item, total })) || [];

  // Ensure we have valid dimensions
  const chartHeight = height || 400;
  const outerRadius = Math.min(chartHeight, 400) / 3;

  return (
    <ChartContainer
      title={null}
      asymRadius={asymRadius}
      reverse={reverse}
      radius={radius}
      height={height}
    >
      <ChartHeader>
        {title && <h3 style={{ margin: 0, fontSize: '1.25rem' }}>{title}</h3>}
        {showLegend && (
          <LegendWrapper>
            {dataWithTotal.map((entry, index) => (
              <LegendItem
                key={entry.name}
                $active={activeIndex === index}
                onMouseEnter={() => setActiveIndex(index)}
                onMouseLeave={() => setActiveIndex(null)}
              >
                <LegendDot $color={entry.color || colors[index % colors.length]} />
                <span style={{ opacity: 0.8 }}>{entry.name}</span>
              </LegendItem>
            ))}
          </LegendWrapper>
        )}
      </ChartHeader>
      <ResponsiveContainer width="100%" height="100%">
        <RechartsPieChart>
          <defs>
            <filter id="shadow">
              <feDropShadow dx="0" dy="0" stdDeviation="1" floodOpacity="0.5" />
            </filter>
          </defs>
          <Pie
            data={dataWithTotal}
            cx="50%"
            cy="45%"
            labelLine={false}
            label={showLabels ? renderCustomizedLabel : null}
            outerRadius={outerRadius}
            innerRadius={innerRadius}
            dataKey={dataKey}
            nameKey={nameKey}
            isAnimationActive={false}
          >
            {dataWithTotal?.map((entry, index) => (
              <Cell 
                key={`cell-${index}`} 
                fill={entry.color || colors[index % colors.length]}
                opacity={activeIndex === null || activeIndex === index ? 1 : 0.5}
                style={{ 
                  filter: `drop-shadow(0px 2px 4px rgba(0,0,0,0.2))`,
                  transition: 'opacity 0.2s ease'
                }}
              />
            )) || null}
          </Pie>
          <Tooltip 
            content={<CustomTooltip />}
            wrapperStyle={{ outline: 'none' }}
            isAnimationActive={false}
          />
        </RechartsPieChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
};

PieChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    color: PropTypes.string
  })).isRequired,
  title: PropTypes.string,
  asymRadius: PropTypes.string,
  reverse: PropTypes.bool,
  radius: PropTypes.string,
  height: PropTypes.number,
  dataKey: PropTypes.string,
  nameKey: PropTypes.string,
  innerRadius: PropTypes.number,
  colors: PropTypes.arrayOf(PropTypes.string),
  showLabels: PropTypes.bool,
  showLegend: PropTypes.bool,
  animate: PropTypes.bool,
};

export default PieChart; 