import React from 'react';
import PropTypes from 'prop-types';
import {
  TableContainer,
  Table,
  TableWrapper,
  TableScroller
} from './styles/TableStyles';
import TableSearch from './components/TableSearch';
import TableHeader from './components/TableHeader';
import TableBody from './components/TableBody';
import TablePagination from './components/TablePagination';
import { useTableSearch } from './hooks/useTableSearch';
import { useTableSort } from './hooks/useTableSort';
import { useTablePagination } from './hooks/useTablePagination';
import styled from 'styled-components';
import { FiChevronDown, FiChevronUp, FiMoreVertical, FiEye, FiEyeOff, FiSearch } from 'react-icons/fi';

const EmptyState = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  color: var(--text-secondary);
  font-size: 0.9rem;
`;

const DataTable = ({
  data = [],
  columns = [],
  pagination = { enabled: true, pageSize: 10 },
  sortable = true,
  onRowClick,
  actions,
  loading = false,
  className,
  asymRadius,
  reverse,
  radius
}) => {
  // Validate inputs
  if (!Array.isArray(data)) {
    console.warn('DataTable: data prop must be an array, received:', typeof data);
    data = [];
  }

  if (!Array.isArray(columns)) {
    console.warn('DataTable: columns prop must be an array, received:', typeof columns);
    columns = [];
  }

  // Ensure pagination object has required properties
  pagination = {
    enabled: true,
    pageSize: 10,
    ...pagination
  };

  const {
    searchColumn,
    setSearchColumn,
    searchValue,
    setSearchValue,
    columnTypes,
    columnOptions,
    enumOptions,
    filteredData
  } = useTableSearch(data, columns);

  const {
    sortConfig,
    sortedData,
    handleSort
  } = useTableSort(filteredData);

  const {
    currentPage,
    setCurrentPage,
    paginatedData,
    totalPages
  } = useTablePagination(sortedData, pagination.pageSize);

  // Ensure that the search value is being updated correctly
  const handleSearchChange = (column, value) => {
    // Handle if column is an object (from Select component)
    const columnValue = typeof column === 'object' ? column.value : column;
    const searchVal = typeof value === 'object' ? value.value : value;
    
    setSearchColumn(columnValue);
    setSearchValue(searchVal);
  };

  // Early return for empty columns
  if (columns?.length === 0) {
    return null;
  }

  // Early return for empty data
  if (data?.length === 0) {
    return (
      <TableContainer
        className={className}
        asymRadius={asymRadius}
        reverse={reverse}
        radius={radius}
      >
        <EmptyState>No data available</EmptyState>
      </TableContainer>
    );
  }

  return (
    <TableContainer
      className={className}
      asymRadius={asymRadius}
      reverse={reverse}
      radius={radius}
    >
      {/* <TableSearch
        columnOptions={columnOptions}
        enumOptions={enumOptions}
        columnTypes={columnTypes}
        searchColumn={searchColumn}
        searchValue={searchValue}
        onColumnChange={setSearchColumn}
        onValueChange={setSearchValue}
        onSearchChange={handleSearchChange}
      /> */}

      <TableWrapper>
        <TableScroller>
          <Table>
            <TableHeader
              columns={columns}
              sortable={sortable}
              sortConfig={sortConfig}
              onSort={handleSort}
              columnTypes={columnTypes}
            />

            {paginatedData.length > 0 ? (
              <TableBody
                data={paginatedData}
                columns={columns}
                onRowClick={onRowClick}
                columnTypes={columnTypes}
              />
            ) : (
              <tbody>
                <tr>
                  <td colSpan={columns.length}>
                    <EmptyState>No matching results found</EmptyState>
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
        </TableScroller>
      </TableWrapper>

      {pagination.enabled && (
        <TablePagination
          currentPage={currentPage}
          pageSize={pagination.pageSize}
          totalItems={sortedData.length}
          onPageChange={setCurrentPage}
          totalPages={totalPages}
        />
      )}
    </TableContainer>
  );
};

DataTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['enum', 'text']), // Optional explicit type
    render: PropTypes.func
  })).isRequired,
  pagination: PropTypes.shape({
    enabled: PropTypes.bool,
    pageSize: PropTypes.number
  }),
  sortable: PropTypes.bool,
  onRowClick: PropTypes.func,
  actions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    handler: PropTypes.func.isRequired,
    icon: PropTypes.elementType
  })),
  loading: PropTypes.bool,
  className: PropTypes.string,
  asymRadius: PropTypes.string,
  reverse: PropTypes.bool,
  radius: PropTypes.string
};

export default DataTable; 