import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Input from '../../../atomic/atoms/form-elements/Input';
import Button from '../../../atomic/atoms/Button/Button'; // Assuming there's a Button component
import Select from '../../../atomic/atoms/Select/Select';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { CMS_API_URL } from '../../../js/Helper';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  width: 100%;
  background: var(--portal-background);
  border-radius: 8px;
  box-shadow: 0 4px 24px -1px rgba(0, 0, 0, 0.2);

  @media (min-width: 768px) {
    width: 50%;
  }
`;

const TransitionWrapper = styled.div`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  max-height: ${({ visible }) => (visible ? '100px' : '0')};
  overflow: hidden;
  transition: opacity 0.3s ease, max-height 0.3s ease;
`;

const TicketSender = ({ ticketOptions, eventId }) => {
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [email, setEmail] = useState('');
  const [quantity, setQuantity] = useState(1)
  const [quantityOptions, setQuantityOptions] = useState([]);
  const [responseMessage, setResponseMessage] = useState('');

  useEffect(() => {
    if (selectedTicket) {
      const selected = ticketOptions.find(ticket => {
        console.log('Checking ticket ID:', ticket.id);
        return ticket.id === Number(selectedTicket);
      });
      if (selected) {
        console.log('Found Ticket:', selected);
        setQuantityOptions(Array.from({ length: selected.remaining > 10 ? 10 : selected.remaining }, (_, i) => ({
          value: i + 1,
          label: `${i + 1}`
        })));
      } else {
        console.warn('Ticket not found');
      }
    }
  }, [selectedTicket, ticketOptions]);


  const mutation = useMutation({
    mutationFn: async () => {
      const response = await axios.post(`${CMS_API_URL}/orders/create-send`, {
        email,
        orderItems: [{
          product_id: Number(selectedTicket),
          quantity: Number(quantity)
        }],
        orderable: {
            id: Number(eventId),
            type: 'event'
        }
      });

      setResponseMessage(response.data.message);
    }
  });

  const handleSendTickets = () => {
    mutation.mutate();
  };

  if (!ticketOptions) return null;


  return (
    <Container>
      <h4 style={{ textAlign: 'center' }}>SEND TICKETS</h4>
      <Select
        options={ticketOptions.map(ticket => ({ value: ticket.id, label: ticket.name }))}
        value={selectedTicket}
        placeholder="Select Ticket Type"
        onChange={(e) => setSelectedTicket(e.target.value)}
      />

      <TransitionWrapper visible={true}>
        <Select
          options={quantityOptions}
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
          placeholder="Select Quantity"
        />
      </TransitionWrapper>

      <TransitionWrapper visible={true}>
        <Input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter Email"
          autoComplete="email"
        />
      </TransitionWrapper>

      <Button 
      onClick={handleSendTickets} 
      disabled={!selectedTicket || !email} 
      variant='primary'
      loading={mutation.isLoading || mutation.isPending}
      >
        Send Tickets
      </Button>

      {responseMessage && (
        <p style={{ textAlign: 'center', color: 'var(--success-colour)' }}>{responseMessage}</p>
      )}

    </Container>
  );
};

export default TicketSender; 