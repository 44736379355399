import {Outlet, Route, Routes, useNavigate, useParams} from 'react-router-dom'
import React, {useContext, useEffect, useMemo, useState} from 'react'
import {ToastContainer} from 'react-toastify';
import {ErrorBoundary} from '@sentry/react';
import ErrorFallback from './pages/Error';
import { SessionProvider } from './features/session';

import 'react-toastify/dist/ReactToastify.min.css';
import '/src/components/forms/forms.css';

// Layouts
import {SinglePageLayout} from './layout/SinglePageLayout/SinglePageLayout';
import {StandardPageLayout} from './layout/StandardPageLayout';

// Home
import Home from './pages';

// Event pages
import Event from './features/events';
import EventTest from './features/events/pages/EventTest';

// E-commerce pages
import CheckoutPage from './features/ecommerce/checkout/pages/CheckoutPage';

// Other pages
import {TicketTermsConditions} from './pages/TermsConditions';
import {PrivacyPolicy} from './pages/PrivacyPolicy';
import TempHome from './pages/TempHome';


// Regular Imports (Recommended to keep as is due to their likely necessity and small size)
import ScrollToTop from './components/ScrollToTop';
import {AuthContext} from './features/authentication';
import {FileNotFoundPage} from './pages/FileNotfoundPage';
import {PasswordReset} from "./features/authentication/pages/PasswordReset.jsx";
import {Card} from "./features/cards/pages/index.js";
import {EventHome} from "./features/events/pages/index.js";
import {API_URL, scrollToElement} from "./js/Helper.js";
import axios from "axios";
import {LinksPage} from "./features/links/pages/index.js";
import PrimaryFeedLayout from "./layout/feeds/PrimaryFeedLayout.jsx";
import {StandardFeedpage} from "./pages/StandardFeedpage.jsx";
import UserHomePage from "./features/user/pages/UserArea/UserHomePage.jsx";
import UserPagesLayout from "./layout/UserPagesLayout.jsx";
import Sandbox from "./features/sandbox/pages/Sandbox.jsx";
import EventBusinessList from "./features/events/pages/EventBusinessList.jsx";
// import Pack from "./features/packs/pages/Pack.jsx";
import PrimaryCardPage from "./features/cards/pages/PrimaryCardPage.jsx";

import Pack from "./features/packs/pages/Pack.jsx";
import CardLis from "./features/events/pages/CardLis.jsx";

import AffiliateSignup from "./features/events/pages/AffiliateSignup.jsx";
import ProposalsPage from './features/proposals/pages/ProposalsPage.jsx';
import CampaignPurchaseLandingPage from "./features/moments/pages/CampaignPurchaseLandingPage.jsx";
import YourComponent from './features/YourFeature/YourComponent.jsx';
import {Paragraph} from './components/ui/typography/Paragraph.jsx';
import {FullPageFlexCenter} from './layout/styles/fullPageFlexCenter.js';
import {ButtonComponent} from './components/ui/buttons/ButtonComponent.jsx';
import ProductPage from './features/ecommerce/products/pages/ProductPage.jsx';
import {generateUUID} from "./js/utils/utils.js";
import InvestmentProposal from './features/campaigns/pages/InvestmentProposal.jsx';
import {PrimaryProposalPage} from "./features/proposals/pages/index.js";
import BusinessHome from "./pages/BusinessHome/BusinessHome.jsx";
import HomeEvents from "./pages/BusinessHome/pages/HomeEvents.jsx";
import ErrorBoundaryTest from './features/events/components/__tests__/ErrorBoundaryTest';

// Feature flags
import { getVersionedComponent } from './js/helpers/featureFlags';
import EventV1 from './features/events/pages/Event';
import EventV2 from './featuresV2/events/pages/Event';
import BaseConfirmation from './featuresV2/confirmation/pages/BaseConfirmation/BaseConfirmation.jsx';
import { BasketProvider } from './featuresV2/ecommerce/basket/context/BasketContext';
import { ProductProvider } from './featuresV2/ecommerce/products/context/ProductContext';
import UpdateRequestUI from './atomic/molecules/UpdateRequestUI/UpdateRequestUI';
import PortalRoutes, { generateRoutesFromConfig, PORTAL_BASE_PATH } from './portal/routes/portalRoutes';
import Applications from './portal/applications/Applications.jsx';
import { getApplicationConfig } from './portal/config/applicationMap.js';
import PortalLayout from './portal/layouts/PortalLayout.jsx';
import PortalPage from './portal/pages/PortalPage.jsx';
import EventDashboard from './portal/pages/Events/EventDashboard.jsx';

function unregisterServiceWorkers() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => {
        registration.unregister().then((success) => {
          if (success) {
            console.log('Service worker unregistered successfully.');
          } else {
            console.log('Service worker unregistration failed.');
          }
        });
      });
    }).catch((error) => {
      console.error('Error fetching service worker registrations:', error);
    });
  }
}

function App() {
  const { user, isAuthenticated } = useContext(AuthContext);
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const section = searchParams.get('section')
  const navigate = useNavigate();

    useEffect(() => {
    scrollToElement(section);
  }, [section]);

  useEffect(() => {

    unregisterServiceWorkers();
    const checkVersion = async () => {
      try {
        const serverResponse = await axios.get(API_URL + '/version');
        const serverData = serverResponse.data;

        const versionResponse = await axios.get('/version.json');
        const clientData = versionResponse.data;

        if (serverData.deploymentId !== clientData.deploymentId && clientData.deploymentId !== 'local') {
          setIsUpdateAvailable(true);
        }
      } catch (error) {
        console.error('Error checking version:', error);
      }
    };

    // checkVersion();


    if(!isAuthenticated) {
      sessionStorage.setItem('appSession', JSON.stringify({
        id: generateUUID()
      }));

    }

    const intervalId = setInterval(checkVersion, 5 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, []);



  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => {
        registration.unregister().then(r => {
          console.log('Service worker unregistered');
        }).catch(e => {
          console.warn('Service worker unregistration failed', e);
        });
      });
    });
  } else {
    console.info('Service workers not found');
  }

  const handleUpdate = () => {
    window.location.reload();
  };

  if (isUpdateAvailable) {
    return (
      <UpdateRequestUI 
        isOpen={isUpdateAvailable}
        onClose={() => setIsUpdateAvailable(false)}
        onUpdate={handleUpdate}
      />
    );
  }

  // Route components with version control
  const EventComponent = getVersionedComponent({ 
    V1: EventV1, 
    V2: EventV2 
  }, 'EVENTS');

  
  return (
    <SessionProvider>
      <BasketProvider>
        <ErrorBoundary
          fallback={ErrorFallback}
          beforeCapture={(scope) => {
            scope.setTag("location", "error_boundary");
          }}
        >
          <ScrollToTop />
          <Routes>
            <Route path='applications' element={<Applications />} />
          
            <Route path={PORTAL_BASE_PATH} element={<PortalLayout />}>
              <Route path='events/:id/dashboard' element={<EventDashboard />} />
              <Route index element={<PortalPage />} />
            </Route>

            <Route path='test' element={<YourComponent />} />
            <Route path='error' element={<ErrorFallback />}></Route>
            <Route path={'packs/:slug/:contactId'} element={<Pack />}></Route>

            <Route path={'proposals/:slug'} element={
              <FullPageFlexCenter>
                <div style={{textAlign: 'center'}}>
                  <Paragraph>This page has been moved.</Paragraph>
                  <ButtonComponent
                   variant={'outlined'} 
                   text={'Try This One' } 
                   onClick={() => navigate(`/proposals/nano-influencers/bmf-campaign`)} 
                  />
                </div>  
              </FullPageFlexCenter>
            }></Route>
            <Route path={'card/:tierId/members'} element={<CardLis />} ></Route>

            <Route path={'card/new'} element={<PrimaryCardPage />} />

            <Route element={<SinglePageLayout/>}>

              <Route path={'p'} element={<BusinessHome />}>
                <Route path={':slug'} element={<PrimaryProposalPage/>}></Route>
                <Route path={':slug/automation'} element={<h1>Automation</h1>}></Route>
                <Route path={':slug/events'} element={<HomeEvents/>}></Route>
                <Route path={':slug/content'} element={<h1>Content</h1>}></Route>
              </Route>

              <Route path={'e/:slug/home'} element={<EventHome />} />

              <Route path={'event'}>
                <Route path={''} element={<Event/>} />
                <Route path={':id'} element={<Event/>} />
                <Route path={':slug/home'} element={<EventHome />} />
                <Route path={':slug/home/:id'} element={<EventHome />} />
                <Route path={':slug/affiliate/signup'} element={<AffiliateSignup />} ></Route>
                <Route path={':eventId/guestlists/:guestlistId'} element={<Event/>} />
              </Route>
              <Route path={':slug/events'} element={<EventBusinessList />} />

              <Route path={'sandbox/:component'} element={<Sandbox />}/>

              <Route path='/e/:slug' element={<EventComponent />} />

              <Route path={'/password-reset'} element={<PasswordReset />}></Route>
              <Route index element={ user?.id === 427 ? <Home/> : < TempHome />}/>
              <Route path='home' element={<TempHome />}/>

              {/*<Route path='/business/:slug' element={<BusinessSinglePage/>}/>*/}
              {/*<Route path='/reward/:slug' element={<RewardSinglePage/>}/>*/}
              <Route path='/:model' element={<PrimaryFeedLayout/>}></Route>
              {/*<Route path='/:model/:slug/rewards' element={<PrimaryFeedLayout/>}></Route> */}

            </Route>

            <Route path='eventss' element={ <Outlet/> }>
              <Route path=':slug' element={<Event />}></Route>
            </Route>

            <Route>
              <Route path='/confirmation/:id' element={<BaseConfirmation />}/>
            </Route>

            <Route path={'links'}>
              <Route path={':slug'} element={<LinksPage />}></Route>
            </Route>

            <Route path={'user'} element={<UserPagesLayout />} >
              <Route path={'my-account'} element={<UserHomePage />} />
            </Route>

            <Route element={<StandardPageLayout/>}>

            <Route path='product'>
              <Route path=':id' element={<ProductPage />} />
            </Route>

            <Route path='links/:slug' element={<LinksPage />} ></Route>
            <Route path={'proposals/:influencerType/:slug'} element={<ProposalsPage />} ></Route>
            <Route path='proposal/investement' element={<InvestmentProposal />} ></Route>

              <Route path={'campaign/:slug'} element={<CampaignPurchaseLandingPage />} />

              <Route path='checkout/:id' element={<CheckoutPage/> }></Route>
              <Route path='c/:slug' element={<Card />}/>
              <Route path='c/:slug/:subPage' element={<Card page={'partners'} />}/>


              <Route path='/privacy-policy' element={<PrivacyPolicy/>}></Route>
              <Route path='/ticket-terms-conditions' element={<TicketTermsConditions/>}></Route>
               <Route path='/:model/:key/:value' element={<StandardFeedpage/>}></Route>
            </Route>

            <Route path="/test-error" element={<EventTest />} />
            <Route path="/test-error/:type" element={<EventTest />} />
            <Route path="/test-error-boundary" element={<ErrorBoundaryTest />} />

            {/* Event Routes */}
            <Route 
              path="/e/:slug" 
              element={<EventComponent />} 
            />
            <Route 
              path="/events/:slug" 
              element={<EventComponent />} 
            />

            <Route path='*' element={<FileNotFoundPage/>} />
          </Routes>
          <ToastContainer
            position='bottom-center'
            theme='dark'
            closeOnClick
            limit={3}
            autoClose={2500}
            hideProgressBar={true}
            transition: Bounce
            pauseOnHover={true}
            toastStyle={{
              borderRadius: 'var(--border-radius-default)',
              backgroundColor: 'var(--primary-colour)',
              boxShadow: '10px 10px 10px rgba(0,0,0,0.1)',
              marginBottom: '10px'
            }}
            style={{
              margin: '0 auto',
              zIndex:'100000',
              padding: '20px',
            }}
          />
        </ErrorBoundary>
      </BasketProvider>
    </SessionProvider>
  )
}

export default App
