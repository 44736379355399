import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useApiQuery } from "../../../hooks/customHooks";
import { CMS_API_URL } from "../../../js/Helper";
import DataTable from "../../atoms/DataTable/DataTable";
import StatCounter from "../../atoms/StatCounter/StatCounter";
import { BsCashCoin, BsTicket } from "react-icons/bs";
import TicketSender from './TicketSender';
import { useContext } from "react";
import { AuthContext } from "../../../features/authentication";

const DashboardSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding: 1rem;
  gap: 1rem;
  width: 100%;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const columns = [
  { key: 'id', label: 'ID', type: 'text' },
  { key: 'customerName', label: 'Customer', type: 'text' },
  { key: 'customerEmail', label: 'Email', type: 'text' },
  { 
    key: 'amount', 
    label: 'Amount', 
    type: 'text',
  },
  { key: 'quantity', label: 'Quantity', type: 'text' },
  { 
    key: 'paymentStatus', 
    label: 'Payment Status', 
    type: 'enum',
    options: ['Payment Confirmed', 'Pending', 'Failed'],
    render: (value) => (
      <span style={{ 
        padding: '4px 8px', 
        borderRadius: '4px',
        background: value === 'Payment Confirmed' ? 'var(--success-colour)' : 
                   value === 'Pending' ? 'var(--warning-colour)' : 
                   'var(--error-colour)',
        color: 'white',
        fontSize: '0.875rem'
      }}>
        {value}
      </span>
    )
  },
  { 
    key: 'deliveryStatus', 
    label: 'Delivery Status', 
    type: 'enum',
    options: ['Sent', 'Pending', 'Failed'],
    render: (value) => (
      <span style={{ 
        padding: '4px 8px', 
        borderRadius: '4px',
        background: value === 'Sent' ? 'var(--success-colour)' : 
                   value === 'Pending' ? 'var(--warning-colour)' : 
                   'var(--error-colour)',
        color: 'white',
        fontSize: '0.875rem'
      }}>
        {value}
      </span>
    )
  }
];

const EventDashboard = () => {

  const { id } = useParams();
  const { user } = useContext(AuthContext)

  const orders = useApiQuery(`${CMS_API_URL}/events/${id}/orders`)
  const revenue = useApiQuery(`${CMS_API_URL}/events/${id}/revenue`)
  const ticketsSold = useApiQuery(`${CMS_API_URL}/events/${id}/tickets-sold`)
  const products = useApiQuery(`${CMS_API_URL}/events/${id}/products`)


  
  return (
    <>
      <DashboardSection>  
        <StatCounter 
        asymRadius={'xl'}
        label="Revenue"
        value={revenue?.data?.data / 100}
        format="currency"
        icon={BsCashCoin}
        />
        <StatCounter 
        asymRadius={'xl'} 
        label="Tickets Sold" 
        value={ticketsSold?.data?.data} 
        icon={BsTicket}
        />
      </DashboardSection>

  
        <DashboardSection>
          <DataTable 
            columns={columns} 
            data={orders?.data?.orders} 
            sortable={true}
            pagination={{ enabled: true, pageSize: 5 }}
          radius="lg"
        />
      </DashboardSection>

      <DashboardSection>
        <TicketSender ticketOptions={products?.data?.data} eventId={id} />
      </DashboardSection>
    </>
  )
};

export default EventDashboard;