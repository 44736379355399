import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import StatCounter from '../atoms/StatCounter/StatCounter';
import BarChart from '../atoms/charts/components/BarChart/BarChart';
import ProgressCard from '../molecules/ProgressCard/ProgressCard';
import AreaChart from '../atoms/charts/components/AreaChart/AreaChart';
import DataTable from '../atoms/DataTable/DataTable';
import { FiShoppingCart } from 'react-icons/fi';
import LineChart from '../atoms/charts/components/LineChart/LineChart';
import PieChart from '../atoms/charts/components/PieChart/PieChart';

const PageContainer = styled.div`
  min-height: 100vh;
  background: var(--primary-colour);
//   padding: 2rem;
`;

const Section = styled.section`
  margin-bottom: 3rem;
`;

const SectionTitle = styled.h2`
  color: var(--text-colour);
  margin-bottom: 2rem;
  font-size: 1.5rem;
`;

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 3rem;
`;

// Sample data for demonstration
const sampleData = [
  { id: 1, name: 'John Doe', email: 'john@example.com', status: 'Active', orders: 15, revenue: 1250.50, paymentStatus: 'Paid' },
  { id: 2, name: 'Jane Smith', email: 'jane@example.com', status: 'Inactive', orders: 8, revenue: 750.25, paymentStatus: 'Pending' },
  { id: 3, name: 'Bob Johnson', email: 'bob@example.com', status: 'Active', orders: 25, revenue: 2100.75, paymentStatus: 'Paid' },
  { id: 4, name: 'Alice Brown', email: 'alice@example.com', status: 'Active', orders: 20, revenue: 1800.50, paymentStatus: 'Paid' },
  { id: 5, name: 'Mike Davis', email: 'mike@example.com', status: 'Inactive', orders: 10, revenue: 900.25, paymentStatus: 'Refunded' },
  { id: 6, name: 'Emily Taylor', email: 'emily@example.com', status: 'Active', orders: 30, revenue: 2700.75, paymentStatus: 'Paid' },
  { id: 7, name: 'Sarah Lee', email: 'sarah@example.com', status: 'Active', orders: 22, revenue: 2200.50, paymentStatus: 'Paid' },
];

const columns = [
  { key: 'name', label: 'Name', type: 'text' },
  { key: 'email', label: 'Email', type: 'text' },
  { key: 'orders', label: 'Orders', type: 'text' },
  { key: 'paymentStatus', label: 'Revenue', type: 'text' },
  { 
    key: 'status', 
    label: 'Status',
    type: 'enum',
    render: (value) => (
      <span style={{ 
        padding: '4px 8px', 
        borderRadius: '4px',
        background: value === 'Active' ? 'var(--success-colour)' : 'var(--error-colour)',
        color: 'white',
        fontSize: '0.875rem'
      }}>
        {value}
      </span>
    )
  },
  { 
    key: 'revenue', 
    label: 'Revenue',
    render: (value) => new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(value)
  }
];

const ticketData = [
  {
    label: 'VIP Tickets',
    value: 75,
    max: 100,
    threshold: 90
  },
  {
    label: 'Regular Tickets',
    value: 150,
    max: 200,
    threshold: 90
  },
  {
    label: 'Early Bird',
    value: 50,
    max: 50,
    threshold: 90
  }
];

const venueData = [
  {
    label: 'Main Hall',
    value: 450,
    max: 500,
    threshold: 95
  },
  {
    label: 'VIP Lounge',
    value: 85,
    max: 100,
    threshold: 90
  }
];

const salesData = [
  { name: 'Mon', tickets: 120, revenue: 1400 },
  { name: 'Tue', tickets: 180, revenue: 2100 },
  { name: 'Wed', tickets: 150, revenue: 1800 },
  { name: 'Thu', tickets: 220, revenue: 2600 },
  { name: 'Fri', tickets: 350, revenue: 4200 },
  { name: 'Sat', tickets: 400, revenue: 4800 },
  { name: 'Sun', tickets: 280, revenue: 3400 },
];

const categoryData = [
  { name: 'VIP', value: 400 },
  { name: 'Regular', value: 300 },
  { name: 'Early Bird', value: 200 },
  { name: 'Student', value: 100 }
];

const hourlyData = [
  { hour: '12AM', vip: 30, regular: 45, early: 20 },
  { hour: '4AM', vip: 25, regular: 35, early: 15 },
  { hour: '8AM', vip: 40, regular: 60, early: 30 },
  { hour: '12PM', vip: 55, regular: 80, early: 45 },
  { hour: '4PM', vip: 70, regular: 95, early: 60 },
  { hour: '8PM', vip: 85, regular: 120, early: 75 },
];

const weeklyTrends = [
  { day: 'Mon', attendance: 2400, capacity: 3000 },
  { day: 'Tue', attendance: 2800, capacity: 3000 },
  { day: 'Wed', attendance: 2900, capacity: 3000 },
  { day: 'Thu', attendance: 2700, capacity: 3000 },
  { day: 'Fri', attendance: 2600, capacity: 3000 },
  { day: 'Sat', attendance: 2900, capacity: 3000 },
  { day: 'Sun', attendance: 3000, capacity: 3000 },
];

const PortalPage = () => {
  const { application } = useParams();

  return (
    <PageContainer>
      <Section>
        <SectionTitle>Overview</SectionTitle>
        <StatsGrid>
          <StatCounter
            value={567}
            label="Orders"
            icon={FiShoppingCart}
            trend={-50}
            asymRadius="xl"
          />
        </StatsGrid>
      </Section>

      <Section>
        <SectionTitle>Weekly Performance</SectionTitle>
        <LineChart
          title="Ticket Sales & Revenue"
          data={salesData}
          lines={[
            { dataKey: 'tickets', color: 'var(--accent-colour)' },
            { dataKey: 'revenue', color: 'var(--success-colour)' }
          ]}
          asymRadius="xl"
          height={350}
        />
      </Section>

      <Section>
        <SectionTitle>Detailed Analytics</SectionTitle>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(400px, 1fr))', gap: '1.5rem' }}>
          <BarChart
            title="Hourly Distribution"
            data={hourlyData}
            bars={[
              { dataKey: 'vip', color: 'var(--accent-colour)' },
              { dataKey: 'regular', color: 'var(--success-colour)' },
              { dataKey: 'early', color: 'var(--info-colour)' }
            ]}
            xAxisDataKey="hour"
            asymRadius="xl"
            height={300}
            stacked={true}
          />
          
          <PieChart
            title="Ticket Categories"
            data={categoryData}
            asymRadius="xl"
            height={300}
            innerRadius={60} // Makes it a donut chart
          />
        </div>
      </Section>

      <Section>
        <SectionTitle>Attendance Trends</SectionTitle>
        <AreaChart
          title="Weekly Attendance vs Capacity"
          data={weeklyTrends}
          areas={[
            { dataKey: 'attendance', color: 'var(--accent-colour)' },
            { dataKey: 'capacity', color: 'var(--success-colour)' }
          ]}
          xAxisDataKey="day"
          asymRadius="xl"
          height={300}
        />
      </Section>

      <Section>
        <SectionTitle>Ticket Sales</SectionTitle>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', gap: '1.5rem' }}>
          <ProgressCard
            title="Event Capacity"
            items={ticketData}
            asymRadius="xl"
          />
          <ProgressCard
            title="Venue Capacity"
            items={venueData}
            asymRadius="xl"
            reverse
          />
        </div>
      </Section>

      <Section>
        <SectionTitle>Recent Activity</SectionTitle>
        <DataTable
          data={sampleData}
          columns={columns}
          sortable={true}
          pagination={{ enabled: true, pageSize: 5 }}
          radius="lg"
        />
      </Section>
    </PageContainer>
  );
};

export default PortalPage; 