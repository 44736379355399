import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import { FiArrowUp, FiArrowDown } from 'react-icons/fi';
import Box from '../../../atomic/atoms/Box/Box';
import Tag from '../../../atomic/atoms/Tag/Tag';

const Container = styled(Box)`
  padding: 2rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;
  flex: 1;
  min-width: 300px;
  max-width: 400px;
  min-height: 125px;
  background: ${({ theme }) => theme?.baseColors?.primary 
    ? `${theme.baseColors.primary}1A` // 1A is 10% opacity in hex
    : 'rgba(42, 45, 58, 0.1)' // fallback color
  };
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  // border: 0.5px solid var(--accent-colour);
  box-shadow: 
    0 4px 24px -1px rgba(0, 0, 0, 0.2),
    0 2px 8px -1px rgba(0, 0, 0, 0.1),
    inset 0 1px 0 0 rgba(255, 255, 255, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;


  &:hover {
    transform: translateY(-2px);
    box-shadow: 
      0 8px 32px -2px rgba(0, 0, 0, 0.25),
      0 4px 12px -2px rgba(0, 0, 0, 0.15),
      inset 0 1px 0 0 rgba(255, 255, 255, 0.15);
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.4rem;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  background: ${props => props.$iconBg || 'var(--accent-colour)'};
  // box-shadow: 
  //   0 4px 24px -1px rgba(0, 0, 0, 0.2),
  //   0 2px 8px -1px rgba(0, 0, 0, 0.1),
  //   inset 0 1px 0 0 rgba(255, 255, 255, 0.1);
  color: white;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const Value = styled(motion.div)`
  font-size: 2.5rem;
  font-weight: 600;
  color: white;
  text-align: right;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  letter-spacing: -0.02em;
`;

const Label = styled.div`
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  letter-spacing: 0.02em;
`;

const TagsContainer = styled.div`
  position: absolute;
  bottom: -6px;
  right: 20px;
  display: flex;
  gap: 8px;
  padding: 0 20px;
`;

const LoadingShimmer = styled(motion.div)`
  width: 100px;
  height: 40px;
  background: linear-gradient(
    90deg,
    ${({ theme }) => theme?.baseColors?.primary 
      ? `${theme.baseColors.primary}33` // 20% opacity
      : 'rgba(255, 255, 255, 0.2)'},
    ${({ theme }) => theme?.baseColors?.primary 
      ? `${theme.baseColors.primary}66` // 40% opacity
      : 'rgba(255, 255, 255, 0.4)'},
    ${({ theme }) => theme?.baseColors?.primary 
      ? `${theme.baseColors.primary}33` // 20% opacity
      : 'rgba(255, 255, 255, 0.2)'}
  );
  background-size: 200% 100%;
  border-radius: 8px;
  animation: shimmer 1.5s infinite linear;

  @keyframes shimmer {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }
`;

const StatCounter = ({
  value,
  label,
  icon: Icon,
  iconBg = 'transparent',
  prefix = '',
  suffix = '',
  trend,
  format = 'number',
  animation = true,
  asymRadius,
  reverse,
  radius,
  className,
  isLoading = false
}) => {
  const formatValue = (val) => {
    if (isLoading || val === undefined || val === null || isNaN(val)) {
      return null;
    }
    
    let formatted = val;
    
    switch (format) {
      case 'currency':
        formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'GBP'
        }).format(val);
        break;
      case 'percentage':
        formatted = `${val}%`;
        break;
      default:
        formatted = new Intl.NumberFormat('en-US').format(val);
    }

    return `${prefix}${formatted}${suffix}`;
  };

  return (
    <Container
      as={motion.div}
      className={className}
      initial={animation ? { opacity: 0, y: 20 } : false}
      animate={animation ? { opacity: 1, y: 0 } : false}
      transition={{ duration: 0.3 }}
      asymRadius={asymRadius}
      reverse={reverse}
      radius={radius}
    >
      <Header>
        {Icon && (
          <IconWrapper $iconBg={iconBg}>
            {<Icon size={30} color="var(--accent-colour)" />}
          </IconWrapper>
        )}
        <ContentWrapper>
          <Label>{label}</Label>
        </ContentWrapper>
      </Header>
      
      <AnimatePresence mode="wait">
        {isLoading || !formatValue(value) ? (
          <LoadingShimmer
            key="loader"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          />
        ) : (
          <Value
            key={value}
            initial={animation ? { opacity: 0, y: -20 } : false}
            animate={animation ? { opacity: 1, y: 0 } : false}
            exit={animation ? { opacity: 0, y: 20 } : false}
            transition={{ duration: 0.2 }}
          >
            {formatValue(value)}
          </Value>
        )}
      </AnimatePresence>

      {!isLoading && trend !== undefined && (
        <TagsContainer>
          <Tag
            background={trend > 0 ? 'var(--success-colour)' : 'var(--error-colour)'}
          >
            {trend > 0 ? <FiArrowUp /> : <FiArrowDown />}
            {Math.abs(trend)}%
          </Tag>
        </TagsContainer>
      )}
    </Container>
  );
};

StatCounter.propTypes = {
  value: PropTypes.number,
  label: PropTypes.string.isRequired,
  icon: PropTypes.elementType,
  iconBg: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  trend: PropTypes.number,
  format: PropTypes.oneOf(['number', 'currency', 'percentage']),
  animation: PropTypes.bool,
  asymRadius: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', 'xxl']),
  reverse: PropTypes.bool,
  radius: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', 'xxl']),
  className: PropTypes.string,
  isLoading: PropTypes.bool
};

export default StatCounter; 