import { makeRequest } from '../../../../services/axios';

/**
 * Basket API utilities for handling basket operations
 */
const basketApi = {
  /**
   * Add an item to the basket
   * @param {Object} item - Item to add
   * @returns {Promise} API response
   */
  addItem: async (item, basketId, quantity) => {
    item.quantity = quantity;
    return await makeRequest('POST', `basket/${basketId}/add-product/${item.id}`, item);
  },

  /**
   * Remove an item from the basket
   * @param {string} itemId - ID of item to remove
   * @returns {Promise} API response
   */
  removeItem: async (itemId, basketId) => {
    return await makeRequest('DELETE', `basket/${basketId}/remove-product/${itemId}`);
  },

  /**
   * Update item quantity in basket
   * @param {string} itemId - ID of item to update
   * @param {number} quantity - New quantity
   * @returns {Promise} API response
   */
  updateQuantity: async (basketId, itemId, productId, quantity) => {
    return await makeRequest('PATCH', `basket/${basketId}/order-items/${itemId}/update-quantity/${quantity}`, {
      productId
    });
  },

  /**
   * Calculate fees for basket items
   * @param {Array} items - Basket items
   * @returns {Promise} API response with calculated fees
   */
  calculateFees: async (items) => {
    return await makeRequest('POST', 'api/basket/calculate-fees', { items });
  },

  /**
   * Get current basket state from server
   * @returns {Promise} API response with basket state
   */
  getBasket: async () => {
    return await makeRequest('GET', '/api/basket');
  }
};

export default basketApi;
