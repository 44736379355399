import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Box from '../../../../atomic/atoms/Box/Box';


const StyledBox = styled(Box)`
//   padding: 1.5rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background: ${({ theme }) => theme?.baseColors?.primary 
    ? `${theme.baseColors.primary}1A`
    : 'rgba(42, 45, 58, 0.1)'
  };
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border: 0.5px solid var(--border-colour);
  box-shadow: 
    0 4px 24px -1px rgba(0, 0, 0, 0.2),
    0 2px 8px -1px rgba(0, 0, 0, 0.1),
    inset 0 1px 0 0 rgba(255, 255, 255, 0.1);
`;

const Title = styled.h3`
  color: var(--text-colour);
  font-size: 1.25rem;D
  margin: 0;
  padding-top: 2rem;
  padding-left: 2rem;
`;

const ChartContainer = ({ 
  title,
  children,
  className,
  asymRadius,
  reverse,
  radius,
  height = 300
}) => {
  return (
    <StyledBox
      className={className}
      asymRadius={asymRadius}
      reverse={reverse}
      radius={radius}
    >
      {title && <Title>{title}</Title>}
      <div style={{ height, width: '100%' }}>
        {children}
      </div>
    </StyledBox>
  );
};

ChartContainer.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  asymRadius: PropTypes.string,
  reverse: PropTypes.bool,
  radius: PropTypes.string,
  height: PropTypes.number
};

export default ChartContainer; 