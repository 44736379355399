import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { createExpressCheckoutSession } from '../../../features/ecommerce/services/stripe/api/expressCheckout';
import { useHandleStripePayment } from '../../../features/ecommerce/services/stripe/hooks/useHandleStripePayment';
import useProductCalculations from '../../../featuresV2/ecommerce/products/hooks/useProductCalculations';
import { useBasket } from '../../../featuresV2/ecommerce/basket/context/BasketContext';
import { loadStripe } from '@stripe/stripe-js';
import { handlePaymentError } from '../../../features/ecommerce/services/stripe/utils/handlePaymentError';

// Styled components for layout
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const PaymentContainer = styled.div`
  width: 100%;
  min-height: 48px;
`;

const ErrorMessage = styled.div`
  color: var(--error-colour);
  font-size: 14px;
  margin-top: 8px;
`;




const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY);

/**
 * ExpressCheckout Component
 * Wraps the ExpressCheckoutForm with Stripe Elements
 * 
 * @param {Object} props - Props passed to the component
 */
const ExpressCheckout = (props) => {
  const { initializeStripe } = useHandleStripePayment();
  const { totalPrice } = useProductCalculations(props.product, props.quantity);
  

  // Options for Stripe Elements - include amount here
  const elementsOptions = React.useMemo(() => ({
    mode: 'payment',
    amount: totalPrice, // Include amount at the Elements level
    currency: 'gbp',
    appearance: {
      theme: 'night',
      variables: {
        colorPrimary: '#0570de',
        colorBackground: '#ffffff',
        colorText: '#30313d',
      }
    }
  }), [totalPrice]); // Update when totalPrice changes

  return (
    <Elements stripe={stripePromise} options={elementsOptions}>
      <ExpressCheckoutForm {...props} />
    </Elements>
  );
};


/**
 * ExpressCheckoutForm Component
 * Handles Stripe Express Checkout integration for quick payment processing with Apple Pay and Google Pay
 * 
 * @param {Object} props
 * @param {number} props.amount - The payment amount in smallest currency unit (e.g., cents)
 * @param {Object} props.product - Product details object containing id and name
 * @param {number} props.quantity - Quantity of the product being purchased
 * @param {string} props.businessName - Name of the business to display in payment sheet
 * @param {Function} props.onSuccess - Callback function called on successful payment
 * @param {Function} props.onError - Callback function called on payment error
 * @param {string} props.returnUrl - URL to redirect after payment completion
 * @param {string} props.basketableId - ID of the basketable item (e.g., event ID)
 * @param {string} props.basketableType - Type of the basketable item (e.g., "event")
 * @param {boolean} props.testMode - Whether to show test mode indicator
 */
const ExpressCheckoutForm = ({
  amount,
  product,
  quantity,
  businessName,
  onSuccess,
  onError,
  returnUrl,
  basketableId,
  basketableType,
  testMode = false
}) => {
  // Stripe hooks and state management
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const expressCheckoutRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);
  const { totalPrice, totalFees } = useProductCalculations(product);
  const { basket } = useBasket();
  
  // Hook to handle Stripe payment functions
  const { getClientSecret, confirmPayment, handleErrorRecovery } = useHandleStripePayment();

  // Function to cleanup the express checkout element
  const cleanupExpressCheckout = useCallback(() => {
    if (expressCheckoutRef.current) {
      console.log('🧹 Cleanup: Destroying express checkout element');
      expressCheckoutRef.current.unmount();
      expressCheckoutRef.current = null;
    }
  }, []);

  // Effect to handle quantity changes
  useEffect(() => {
    const handlePreQuantityChange = () => {
      console.log('🔄 Quantity change detected, cleaning up express checkout');
      cleanupExpressCheckout();
      setIsVisible(false);
    };

    const handlePostQuantityChange = () => {
      console.log('🔄 Post quantity change, setting up express checkout');
      // Add a small delay to ensure the price has been updated
      setTimeout(() => {
        setIsVisible(true);
      }, 100);
    };

    window.addEventListener('preQuantityChange', handlePreQuantityChange);
    window.addEventListener('postQuantityChange', handlePostQuantityChange);

    return () => {
      window.removeEventListener('preQuantityChange', handlePreQuantityChange);
      window.removeEventListener('postQuantityChange', handlePostQuantityChange);
    };
  }, [cleanupExpressCheckout]);

  // Effect to handle price changes
  useEffect(() => {
    if (isVisible && expressCheckoutRef.current) {
      // Clean up and recreate the element when price changes
      cleanupExpressCheckout();
      // Add a small delay before showing again
      setTimeout(() => {
        setIsVisible(true);
      }, 100);
    }
  }, [totalPrice, cleanupExpressCheckout, isVisible]);

  // Effect to set up the Express Checkout element
  useEffect(() => {
    let mounted = true;

    const setupExpressCheckout = async () => {
      if (!stripe || !elements || !isVisible || expressCheckoutRef.current) return;

      try {
        console.log('🔄 Setting up express checkout', {
          amount: totalPrice,
          quantity
        });


        const expressCheckoutElement = elements.create('expressCheckout');
        
        if (!mounted) {
          expressCheckoutElement.unmount();
          return;
        }

        expressCheckoutRef.current = expressCheckoutElement;

        // Event handlers for the express checkout element
        expressCheckoutElement.on('click', async (event) => {
          try {
            const { resolve } = event;

            // Immediately resolve with payment sheet configuration
            resolve({
              emailRequired: true,
              phoneNumberRequired: true,
              billingAddressRequired: true,
              business: {
                name: businessName
              },
              lineItems: [{
                amount: totalPrice,
                name: product.name,
              }]
            });
          } catch (err) {
            const paymentError = handlePaymentError(err);
            setError(paymentError.message);
            onError?.(paymentError);
          }
        });

        // Event handler for confirming the payment
        expressCheckoutElement.on('confirm', async (event) => {
          try {
            setIsProcessing(true);
            console.log('🔥 Confirm handler triggered', { event });

            const paymentDetails = {
              email: event?.billingDetails?.email,
              phone: event?.billingDetails?.phone,
              name: event?.billingDetails?.name,
              billingAddress: event?.billingDetails?.address
            };

            if (!paymentDetails.email || !paymentDetails.phone || !paymentDetails.name) {
              throw new PaymentError('INVALID_REQUEST_ERROR', {
                message: 'Please provide all required customer details'
              });
            }

            console.log('🔥 Payment details', paymentDetails);

            // Create the express checkout session
            const result = await createExpressCheckoutSession({
              amount: totalPrice,
              product_id: product.id,
              quantity,
              paymentType: 'express',
              customerDetails: paymentDetails,
              basketableId,
              basketableType,
              basketId: basket?.id
            });

            if (!result) {
              throw new PaymentError('PROCESSING_ERROR', {
                message: 'Failed to create express checkout session'
              });
            }

            console.log('🔥 Result', { result });

            // Use the updated confirmPayment method
            const paymentIntent = await confirmPayment(
              result.intentId,
              result.basket,
              basketableType,
              stripe,
              elements,
              paymentDetails
            );

            if (paymentIntent.status === 'succeeded') {
              onSuccess?.({
                basket: result.basket,
                intentId: result.intentId,
                customerDetails: paymentDetails
              });
            } else {
              throw new PaymentError('PROCESSING_ERROR', {
                message: 'Payment was not successful'
              });
            }
          } catch (err) {
            console.error('Error in confirm handler:', err);
            const paymentError = handlePaymentError(err);
            setError(paymentError.message);
            onError?.(paymentError);
            
            // Attempt error recovery
            await handleErrorRecovery(paymentError);
          } finally {
            setIsProcessing(false);
          }
        });

        // Handle ready event
        expressCheckoutElement.on('ready', () => {
          console.log('🎯 Express checkout element ready');
        });

        // Handle error event
        expressCheckoutElement.on('error', (err) => {
          console.error('Express checkout element error:', err);
          const paymentError = handlePaymentError(err);
          setError(paymentError.message);
          onError?.(paymentError);
        });

        // Mount the express checkout element
        const mountPoint = document.getElementById('express-checkout-element');
        if (mountPoint && mounted) {
          console.log('🎯 Mounting express checkout element');
          mountPoint.innerHTML = '';
          expressCheckoutElement.mount(mountPoint);
        }
      } catch (err) {
        console.error('Failed to create express checkout element:', err);
        if (mounted) {
          setError('Failed to initialize payment form');
          onError?.(err);
        }
      }
    };

    setupExpressCheckout();

    return () => {
      mounted = false;
      cleanupExpressCheckout();
    };
  }, [stripe, elements, isVisible, totalPrice]); // Added totalPrice as dependency

  return (
    <Container>
      <PaymentContainer style={{ display: isVisible ? 'block' : 'none' }}>
        <div 
          id="express-checkout-element"
          style={{
            minHeight: '48px',
            height: 'fit-content',
            width: '100%',
            opacity: isProcessing ? 0.7 : 1,
            pointerEvents: isProcessing ? 'none' : 'auto'
          }}
        />
      </PaymentContainer>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {testMode && (
        <div style={{ 
          marginTop: '10px',
          padding: '8px',
          borderRadius: '4px',
          fontSize: '12px',
          color: 'rgba(255,255,255,0.6)'
        }}>
          Test Mode Active
        </div>
      )}
    </Container>
  );
};


// PropTypes for validation
ExpressCheckoutForm.propTypes = {
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  returnUrl: PropTypes.string.isRequired,
  testMode: PropTypes.bool,
  amount: PropTypes.number.isRequired,
  product: PropTypes.object.isRequired,
};

ExpressCheckout.propTypes = ExpressCheckoutForm.propTypes;

export default ExpressCheckout;

