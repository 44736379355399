// Animation constants shared between Button and FloatingNav
export const animations = {
  initial: {
    scale: 1,
    backgroundColor: 'inherit',
  },
  hover: {
    scale: 1.02,
    transition: {
      duration: 0.2,
      ease: 'easeInOut',
    }
  },
  tap: {
    scale: 0.98,
  },
};

export const glowEffects = {
  initial: {
    boxShadow: '0 0 0 rgba(255, 255, 255, 0)',
  },
  hover: {
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  },
};

// Ripple effect animation
export const rippleEffect = {
  initial: {
    scale: 0,
    opacity: 0.35,
  },
  animate: {
    scale: 2,
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
};

// Icon transition
export const iconTransition = {
  duration: 0.3,
  type: 'tween',
  ease: 'easeInOut'
};

// Button specific theme constants
export const buttonSizes = {
  small: {
    padding: '8px 16px',
    fontSize: '14px',
    borderRadius: '8px',
    iconSize: '16px',
  },
  medium: {
    padding: '12px 24px',
    fontSize: '16px',
    borderRadius: '10px',
    iconSize: '20px',
  },
  large: {
    padding: '16px 32px',
    fontSize: '18px',
    borderRadius: '12px',
    iconSize: '24px',
  },
};

// Extended button variants
export const buttonVariants = {
  primary: {
    background: 'var(--accent-colour)',
    color: 'var(--text-colour)',
    hoverBackground: 'var(--primary-hover-colour)',
    hoverStyles: `
      box-shadow: var(--shadow-hover-default);
    `
  },
  secondary: {
    background: 'var(--secondary-colour)',
    color: 'var(--text-colour)',
    hoverBackground: 'var(--secondary-hover-colour)',
    hoverStyles: `
      box-shadow: var(--shadow-hover-default);
    `
  },
  ghost: {
    background: 'transparent',
    color: 'var(--text-colour)',
    additionalStyles: `
      border: 1px solid var(--border-colour);
    `,
    hoverBackground: 'var(--glass-background-hover)',
    hoverStyles: `
      border-color: var(--glass-border-hover);
      box-shadow: var(--shadow-hover-default);
    `
  },
  success: {
    background: 'var(--success-colour)',
    color: 'var(--text-colour)',
    hoverBackground: 'var(--success-hover-colour)',
    hoverStyles: `
      box-shadow: var(--shadow-hover-default);
    `
  },
  warning: {
    background: 'var(--warning-colour)',
    color: 'var(--text-colour)',
    hoverBackground: 'var(--warning-hover-colour)',
    hoverStyles: `
      box-shadow: var(--shadow-hover-default);
    `
  },
  danger: {
    background: 'var(--error-colour)',
    color: 'var(--text-colour)',
    hoverBackground: 'var(--error-hover-colour)',
    hoverStyles: `
      box-shadow: var(--shadow-hover-default);
    `
  },
  outline: {
    background: 'transparent',
    color: 'var(--text-colour)',
    additionalStyles: `
      border: 1px solid var(--text-colour);
    `,
    hoverBackground: 'var(--glass-background-hover)',
    hoverStyles: `
      border-color: var(--glass-border-hover);
      box-shadow: var(--shadow-hover-default);
    `
  },
  cta: {
    background: 'var(--accent-colour)',
    color: 'var(--text-colour)',
    additionalStyles: `
      height: 50px;
      border-radius: 12px 4px 12px 4px;
      font-size: 16px;
      font-weight: 600;
      padding: 0 32px;
      @media (max-width: 768px) {
        width: 100%;
      }
    `,
    hoverBackground: 'var(--accent-colour)',
    hoverStyles: `
      filter: brightness(1.1);
      box-shadow: var(--shadow-hover-default);
    `
  }
};

// Gradient variables
export const gradientColors = {
  primary: {
    start: 'var(--primary-colour)',
    end: 'var(--primary-hover-colour)',
    startHover: 'var(--primary-hover-colour)',
    endHover: 'var(--primary-colour)'
  },
  success: {
    start: 'var(--success-colour)',
    end: 'var(--success-hover-colour)',
    startHover: 'var(--success-hover-colour)',
    endHover: 'var(--success-colour)'
  },
  warning: {
    start: 'var(--warning-colour)',
    end: 'var(--warning-hover-colour)',
    startHover: 'var(--warning-hover-colour)',
    endHover: 'var(--warning-colour)'
  },
  danger: {
    start: 'var(--error-colour)',
    end: 'var(--error-hover-colour)',
    startHover: 'var(--error-hover-colour)',
    endHover: 'var(--error-colour)'
  }
};

// State colors for success/error
export const stateColors = {
  success: {
    background: 'var(--success-colour)',
    color: '#FFFFFF',
    icon: '#FFFFFF'
  },
  error: {
    background: 'var(--error-colour)',
    color: '#FFFFFF',
    icon: '#FFFFFF'
  },
  progress: {
    background: 'var(--info-colour)',
    color: '#FFFFFF',
    icon: '#FFFFFF'
  }
};
