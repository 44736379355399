import { keyframes, css } from 'styled-components';

// Entry Animations
export const slideUpKeyframes = keyframes`
  0% {
    transform: translateY(30px) translateX(-50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0) translateX(-50%);
    opacity: 1;
  }
`;

export const popInKeyframes = keyframes`
  0% {
    transform: scale(0.95);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

// Interactive Animations
export const pulseKeyframes = keyframes`
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1.02) rotate(1deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
`;

export const glowKeyframes = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(191, 181, 147, 0.2);
  }
  70% {
    box-shadow: 0 0 0 8px rgba(191, 181, 147, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(191, 181, 147, 0);
  }
`;

// New springy click animation
export const springyKeyframes = keyframes`
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(0.9) rotate(-2deg);
  }
  60% {
    transform: scale(1.05) rotate(1deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
`;

// Premium Float Animation
export const premiumFloatKeyframes = keyframes`
  0%, 100% {
    transform: translateY(0) scale(1);
  }
  50% {
    transform: translateY(-2px) scale(1.05);
  }
`;

// Timing and Easing
export const timings = {
  entry: '0.8s',
  interaction: '0.4s',
  stagger: 0.06,
  spring: '0.5s',
  premium: '0.5s',
  exit: '0.2s',
  normal: '0.2s',
  fast: '0.1s',
  slow: '0.4s'
};

// Smoother easing curves
export const easings = {
  smooth: 'cubic-bezier(0.4, 0, 0.2, 1)',
  entry: 'cubic-bezier(0.0, 0.0, 0.2, 1)',
  spring: 'cubic-bezier(0.34, 1.56, 0.64, 1)',
  springOut: 'cubic-bezier(0.18, 0.89, 0.32, 1.28)',
  premium: 'cubic-bezier(0.4, 0, 0.2, 1)',
  inOutCubic: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
  inOutExpo: 'cubic-bezier(0.87, 0, 0.13, 1)',
  bouncy: 'cubic-bezier(0.68, -0.55, 0.265, 1.55)'
};

// Reusable Animation Mixins
export const premiumAnimation = css`
  transition: all ${timings.premium} ${easings.premium};
`;

export const premiumInteractive = css`
  ${premiumAnimation}
  opacity: 0.7;

  &:hover {
    transform: scale(1.05);
    opacity: 0.9;
  }
`;

export const premiumFloating = css`
  ${premiumInteractive}
  animation: ${premiumFloatKeyframes} 2s ease-in-out infinite;
  opacity: 1;
`;

// Themed Premium Animations
export const premiumAccent = css`
  ${premiumFloating}
  color: var(--accent-colour);
`;

export const premiumError = css`
  ${premiumFloating}
  color: var(--error-colour);
`;

// Premium animation preset
export const premiumAnimationPreset = {
  keyframes: keyframes`
    0% {
      opacity: 0;
      transform: translateY(10px) scale(0.98);
    }
    100% {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  `,
  duration: timings.normal,
  easing: easings.inOutExpo
};

// Usage Example:
/*
import { premiumInteractive, premiumFloating, premiumAccent } from './animations';

const StyledIcon = styled.div`
  // Basic styles...
  
  // For hover effect only:
  ${premiumInteractive}
  
  // For constant floating:
  ${premiumFloating}
  
  // For floating with accent color:
  ${props => props.$isActive && css`
    ${premiumAccent}
  `}
  
  // For error state:
  ${props => props.$error && css`
    ${premiumError}
  `}
`;
*/
