import templateApi from "./template-api/templateApi";

const apiConfig = {
  baseUrl: '/api',
  version: 'v1',
  timeout: 30000,
  

  apis: {  }
};

export default apiConfig;
