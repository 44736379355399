import applications from ".";


const Applications = () => {
  return (
    <div>
      {Object.values(applications).map((application) => {
        return <h1>{application.name}</h1>
      })}
    </div>
  );
};

export default Applications;