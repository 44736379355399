import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

// Basic styled containers
const DefaultContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 2rem;
`;

const SidebarContainer = styled.div`
  display: grid;
  grid-template-columns: 250px 1fr;
  min-height: 100vh;
`;

const ContentContainer = styled.div`
  padding: 2rem;
`;

// Default Layout
export const DefaultLayout = () => (
  <DefaultContainer>
    <Outlet />
  </DefaultContainer>
);

// Sidebar Layout
export const SidebarLayout = () => (
  <SidebarContainer>
    <div>Sidebar Content</div>
    <ContentContainer>
      <Outlet />
    </ContentContainer>
  </SidebarContainer>
);

// Layout Registry
export const layoutRegistry = {
  default: DefaultLayout,
  sidebar: SidebarLayout,
  // Add more layouts as needed
};

// Helper to get layout
export const getLayout = (layoutName) => {
  return layoutRegistry[layoutName] || layoutRegistry.default;
}; 