import api from "../models/model/api";


const section1 = {
    name: 'Home',
    component: 'Dashboard',
    components: [
        {
            name: 'Stat Counter',
            component: 'StatCounter',
            api: api.getUsers
        }
    ]
}

const section2 = {
    name: 'Home',
    component: 'Dashboard',
    components: [
        {
            name: 'Stat Counter',
            component: 'StatCounter'
        }
    ]
}

const homePage = {
    path: 'events/dashboard',
    component: 'Dashboard',
    name: 'Home',
    description: 'Home page',
    icon: 'Home',
    layout: 'default',
    layoutConfig: {
        header: true,
        footer: true,
        sidebar: true
    },
    navigation: {
        showInNav: true,
        order: 1
    },
    sections: [
        section1,
        section2
    ]
}


export default homePage;