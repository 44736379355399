import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

// Basic styled containers
const DefaultTemplateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const GridTemplateContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.columns || 2}, 1fr);
  gap: 2rem;
`;

// Default Template
export const DefaultTemplate = () => (
  <DefaultTemplateContainer>
    <Outlet />
  </DefaultTemplateContainer>
);

// Grid Template
export const GridTemplate = ({ columns = 2 }) => (
  <GridTemplateContainer columns={columns}>
    <Outlet />
  </GridTemplateContainer>
);

// Template Registry
export const templateRegistry = {
  default: DefaultTemplate,
  grid: GridTemplate,
  // Add more templates as needed
};

// Helper to get template
export const getTemplate = (templateName, props = {}) => {
  const Template = templateRegistry[templateName] || templateRegistry.default;
  return props ? <Template {...props} /> : <Template />;
}; 