import React from 'react';
import PropTypes from 'prop-types';
import { Tr, Td } from '../styles/TableStyles';

const TableBody = ({ data = [], columns = [], onRowClick, columnTypes = {} }) => {
  // Validate inputs
  if (!Array.isArray(data)) {
    console.warn('TableBody: data prop must be an array');
    return null;
  }

  if (!Array.isArray(columns)) {
    console.warn('TableBody: columns prop must be an array');
    return null;
  }

  if (!columnTypes || typeof columnTypes !== 'object') {
    console.warn('TableBody: columnTypes prop must be an object');
    return null;
  }

  return (
    <tbody>
      {data.map((row, index) => {
        if (!row) {
          console.warn('TableBody: Invalid row data at index', index);
          return null;
        }

        return (
          <Tr 
            key={row.id || index}
            $clickable={!!onRowClick}
            onClick={() => onRowClick?.(row)}
          >
            {columns.map(column => {
              if (!column || !column.key) {
                console.warn('TableBody: Invalid column configuration', column);
                return null;
              }

              if (!columnTypes[column.key]) return null;

              const value = row[column.key];
              const renderedValue = column.render ? 
                column.render(value, row) : 
                value ?? '';

              return (
                <Td key={column.key}>
                  {renderedValue}
                </Td>
              );
            })}
          </Tr>
        );
      })}
    </tbody>
  );
};

TableBody.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string,
    render: PropTypes.func
  })).isRequired,
  onRowClick: PropTypes.func,
  columnTypes: PropTypes.object.isRequired
};

export default TableBody; 