import apiConfig from './api/apiConfig';
import modelsConfig from './models/modelsConfig';
import pagesConfig from './pages';

const appConfig = {
  id: 'example-app-2',
  name: 'Example Application 2',
  description: 'A demonstration application showing the portal capabilities',
  version: '1.0.0',
  models: modelsConfig,
  api: apiConfig,
  pages: pagesConfig,

  // Application metadata
};

export default appConfig; 