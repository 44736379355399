import React from 'react';
import { Route } from 'react-router-dom';
import PortalLayout from '../layouts/PortalLayout';
import PortalPage from '../pages/PortalPage';
import { getApplicationConfig } from '../config/applicationMap';
import { getComponent } from '../config/portalComponentRegistry.jsx';
import { getLayout } from '../applications/shared/layouts';
import { getTemplate } from '../applications/shared/templates';

export const PORTAL_BASE_PATH = '/portal';

// Dynamic route component that renders based on configuration
const DynamicRoute = ({ config }) => {
  const Component = getComponent(config.component);
  const Layout = getLayout(config.layout);

  if (!Component) return null;

  return (
    <Layout>
      <Component sections={config.sections} />
    </Layout>
  );
};

// Generate routes from config
export const generateRoutesFromConfig = (config) => {
  if (!config) return [];

  // Handle pages routes
  const pageRoutes = Object.entries(config.pages).map(([key, page]) => ({
    key: `page-${key}`,
    path: page.path,
    element: <DynamicRoute config={page} />
  }));

  // Handle model routes
  const modelRoutes = Object.entries(config.models || {}).flatMap(([modelKey, model]) => 
    Object.entries(model.routes || {}).map(([routeKey, route]) => ({
      key: `${modelKey}-${routeKey}`,
      path: route.path,
      element: <DynamicRoute config={route} />
    }))
  );

  return [...pageRoutes, ...modelRoutes];
};

const portalRoutes = (
  <Route path={PORTAL_BASE_PATH} element={<PortalLayout />}>

    <Route index element={<PortalPage />} />
    {generateRoutesFromConfig(getApplicationConfig('example-app-2')).map(route => (
      <Route
        key={route.key}
        path={route.path}
        element={route.element}
      />
    ))}
  </Route>
);

export default portalRoutes; 