import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useApiQuery } from '../../../../hooks/customHooks';
import { useEvent } from '../../../../features/events/js/hooks';
import { useHandleStripePayment } from '../../../../features/ecommerce/services/stripe/hooks/useHandleStripePayment';
import { updateTheme } from '../../../../features/themes/js/utils';
import Image from '../../../../atomic/atoms/Image/Image';
import Heading from '../../../../atomic/atoms/Heading/Heading';
import { Loading } from '../../../../components/ui/Loading';
import InfoStrip from '../../../../atomic/atoms/InfoStrip/InfoStrip';
import { toast } from 'react-toastify';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
  z-index: 2;
  height: 100%;
`;

const BackgroundImage = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: url(${props => props.$backgroundImage});
  background-size: cover;
  background-position: center;
  filter: blur(5px);
  transform: scale(1.1);
  z-index: 0;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: ${props => props.$gradient || 'rgba(0, 0, 0, 0.5)'};
  z-index: 1;
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 2;
  padding: 24px;
  min-height: 50vh;
  height: 80svh;
  max-height: 80svh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ImageContainer = styled.div`
  width: 100%;
  max-width: 500px;
  aspect-ratio: 1/1;
  margin: 32px auto;
`;

export const EventConfirmationLeft = ({ id }) => {
  const { initializeStripe } = useHandleStripePayment();
  const [paymentStatus, setPaymentStatus] = React.useState('loading');
  const searchParams = new URLSearchParams(window.location.search);
  const paymentIntent = searchParams.get('payment_intent');
  const clientSecret = searchParams.get('payment_intent_client_secret');

  React.useEffect(() => {
    const verifyPayment = async () => {
      try {
        const stripe = await initializeStripe();
        if (!stripe) {
          throw new Error('Failed to initialize Stripe');
        }

        const intent = await stripe.retrievePaymentIntent(clientSecret);

        if (!intent) {
          throw new Error('No payment intent found');
        }

        switch (intent.status) {
          case 'succeeded':
            setPaymentStatus('success');
            break;
          case 'processing':
            setPaymentStatus('processing');
            break;
          case 'requires_payment_method':
            setPaymentStatus('failed');
            break;
          default:
            setPaymentStatus('unknown');
        }
      } catch (error) {
        console.error('Error verifying payment:', error);
        setPaymentStatus('error');
      }
    };

    if (paymentIntent && clientSecret) {
      verifyPayment();
    }
  }, [paymentIntent, clientSecret, initializeStripe]);

  const handleBasketLoaded = (event) => {
    updateTheme(event?.theme);
  };

  const { data, isLoading, error } = useApiQuery(
    `basket/${id}`,
    (data) => handleBasketLoaded(data?.data?.basketable),
    {
      enabled: !!id && paymentStatus === 'success'
    }
  );


  const event = data?.data?.basketable;

  // if (paymentStatus === 'loading') {
  //   return <div><Loading/></div>;
  // }

  // if (paymentStatus === 'processing') {
  //   return <div>Your payment is being processed...</div>;
  // }

  if (paymentStatus === 'failed' || paymentStatus === 'error') {
    return (
      <Container>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <Heading level={2}>Payment Failed</Heading>
          <p>There was an issue with your payment. Please try again.</p>
        </motion.div>
      </Container>
    );
  }

  if (error) {
    return <div>Error loading confirmation: {error.message}</div>;
  }

  console.log('event', event);

  if(!event) {
    return;
  }


  return (
    <>
      <BackgroundImage $backgroundImage={event?.image?.cdnUrl} />
      <Overlay $gradient="linear-gradient(to bottom, rgba(42,45,58,0.6) 30%, rgba(42,45,58,0.7) 50%, rgba(42,45,58,0.8) 60%, var(--primary-colour) 80%)" />

      <ContentWrapper>
        <Container>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
          >
            <Heading 
              level={2} 
              align='center' 
              transform='uppercase' 
              gradient='var(--accent-colour)' 
              weight='black'
            >
              Order Confirmation
            </Heading>
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.3 }}
          >
            <ImageContainer>
              <Image
                src={event.image.cdnUrl}
                alt={event.name}
                aspectRatio="1/1"
                borderRadius="10px 60px"
              />
            </ImageContainer>
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.6 }}
          >
            <Heading 
              level={4} 
              align='center' 
              gradient='var(--accent-colour)' 
              // style={{marginBottom: '-20px'}}
            >
              You're going to
            </Heading>
            <Heading level={4} align='center' color='var(--text-colour)'>
              {event.name}
            </Heading>

          </motion.div>
          <InfoStrip text='Emails may end up in  spam.'/>

        </Container>
      </ContentWrapper>
    </>
  );
};

export const EventConfirmationRight = ({ id }) => {
  const { data } = useApiQuery(`basket/${id}`);
  const event = data?.data?.basketable;

  return;

  return (
    <Container style={{width: '100%', background: 'red'}}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.9 }}
      >
        <Heading level={4}>Share Your Experience</Heading>
        <p>Let your friends know you're going!</p>
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8, delay: 1.2 }}
      >
        <Heading level={4}>Event Location</Heading>
        {event.location && (
          <>
            <p>{event.location.name}</p>
            <p>{event.location.address}</p>
          </>
        )}
      </motion.div>
    </Container>
  );
};

EventConfirmationLeft.propTypes = {
  id: PropTypes.string.isRequired
};

EventConfirmationRight.propTypes = {
  id: PropTypes.string.isRequired
};

export default EventConfirmationLeft;
