import React from 'react';
import PropTypes from 'prop-types';
import { Th } from '../styles/TableStyles';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';

const TableHeader = ({ columns = [], sortable = false, sortConfig = {}, onSort = () => {}, columnTypes = {} }) => {
  // Validate inputs
  if (!Array.isArray(columns)) {
    console.warn('TableHeader: columns prop must be an array');
    return null;
  }

  if (!columnTypes || typeof columnTypes !== 'object') {
    console.warn('TableHeader: columnTypes prop must be an object');
    return null;
  }

  return (
    <thead>
      <tr>
        {columns.map(column => {
          if (!column || !column.key) {
            console.warn('TableHeader: Invalid column configuration', column);
            return null;
          }

          return columnTypes[column.key] && (
            <Th 
              key={column.key}
              $sortable={sortable}
              onClick={() => sortable && onSort(column.key)}
            >
              <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                {column.label || column.key}
                {sortable && sortConfig?.key === column.key && (
                  sortConfig.direction === 'asc' ? <FiChevronUp /> : <FiChevronDown />
                )}
              </div>
            </Th>
          );
        })}
      </tr>
    </thead>
  );
};

TableHeader.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string
  })).isRequired,
  sortable: PropTypes.bool,
  sortConfig: PropTypes.shape({
    key: PropTypes.string,
    direction: PropTypes.oneOf(['asc', 'desc'])
  }),
  onSort: PropTypes.func,
  columnTypes: PropTypes.object.isRequired
};

export default TableHeader; 