import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Select from '../../../../../atomic/atoms/Select/Select';
import { useBasket } from '../../context/BasketContext';
import { toast } from 'react-toastify';

const SelectWrapper = styled.div`
  width: 120px;
  flex-shrink: 0;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const QuantitySelect = ({ 
  product,
  maxQuantity = 10,
  minQuantity = 1,
  disabled = false,
  onQuantityChange, 
  onError
}) => {
  const { 
    basket, 
    updateQuantity,
    loading 
  } = useBasket();

  // Find if product is in basket and get its quantity
  const basketItem = basket.orderItems.find(
    item => item.productId === product.id
  );

  // Initialize quantity from basket or default to 1
  const [quantity, setQuantity] = useState(
    basketItem ? String(basketItem.quantity) : '1'
  );

  // Update quantity when basket changes
  useEffect(() => {
    const newBasketItem = basket.orderItems.find(
      item => item.productId === product.id
    );
    if (newBasketItem) {
      setQuantity(String(newBasketItem.quantity));
    }
  }, [basket, product.id]);

  // Calculate the maximum allowed quantity
  const effectiveMax = Math.min(
    6,
    product.maxPerOrder || 8,
    product.remaining || 8
  );

  // Generate quantity options
  const quantityOptions = Array.from(
    { length: effectiveMax - minQuantity + 1 },
    (_, i) => ({ 
      value: String(i + minQuantity), 
      label: String(i + minQuantity) 
    })
  );

  const handleQuantityChange = async (newValue) => {
    const newQuantity = Number(newValue);
    
    onQuantityChange?.(newQuantity);
    
    // Dispatch quantity change start event regardless of basket state
    const preChangeEvent = new CustomEvent('quantityChange', {
      detail: {
        productId: product.id,
        quantity: newQuantity,
        basketItemId: basketItem?.id
      }
    });
    window.dispatchEvent(preChangeEvent);
    
    if (basketItem) {
      // Only make server calls if item is already in basket
      const previousQuantity = quantity;
      
      try {
        console.log('📊 Quantity change initiated', {
          productId: product.id,
          oldQuantity: previousQuantity,
          newQuantity,
          basketItemId: basketItem.id
        });

        // Small delay to ensure element is destroyed
        await new Promise(resolve => setTimeout(resolve, 0));

        // Optimistically update UI
        console.log('🔄 Updating quantity state');
        setQuantity(newValue);

        // Notify parent component about quantity change
        onQuantityChange?.(newQuantity);

        const success = await updateQuantity(basketItem.id, newQuantity);

        console.log('success', success);
        console.log('basket', basketItem);

        if (success) {
          console.log('✅ Quantity update successful');
          // Notify successful update
          const successEvent = new CustomEvent('quantityChanged', {
            detail: {
              productId: product.id,
              quantity: newQuantity,
              basketItemId: basketItem.id
            }
          });
          window.dispatchEvent(successEvent);
        } else {
          throw new Error('Failed to update quantity');
        }
      } catch (error) {
        // Revert to previous quantity on error
        console.error('❌ Error updating quantity:', error);
        setQuantity(previousQuantity);
        toast.error(error.message || 'Failed to update quantity. Please try again.');
        onError?.(error.message);
      }
    } else {
      // Just update local state if item is not in basket
      console.log('📝 Updating local quantity state (not in basket)');
      setQuantity(newValue);
      // Notify parent component about quantity change
      onQuantityChange?.(newQuantity);

      // Dispatch success event even when not in basket
      const successEvent = new CustomEvent('quantityChanged', {
        detail: {
          productId: product.id,
          quantity: newQuantity,
          basketItemId: null
        }
      });
      window.dispatchEvent(successEvent);
    }
  };

  return (
    <SelectWrapper>
      <Select
        label="Quantity"
        value={quantity}
        onChange={(e) => handleQuantityChange(e.target.value)}
        options={quantityOptions}
        disabled={disabled || loading}
      />
    </SelectWrapper>
  );
};

export default QuantitySelect;
