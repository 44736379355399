import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ProgressContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ProgressLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-colour);
  font-size: 0.875rem;
`;

const ProgressTrack = styled.div`
  width: 100%;
  height: 12px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  overflow: hidden;
  position: relative;
`;

const ProgressBar = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: ${props => props.$progress}%;
  background: ${props => {
    if (props.$progress >= props.$threshold) {
      return 'var(--success-colour)';
    }
    return 'var(--accent-colour)';
  }};
  transition: width 0.3s ease, background-color 0.3s ease;
`;

const Progress = ({ 
  value = 0,
  max = 100,
  label,
  showValue = true,
  threshold = 100,
  className 
}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress((value / max) * 100);
  }, [value, max]);

  return (
    <ProgressContainer className={className}>
      {(label || showValue) && (
        <ProgressLabel>
          {label && <span>{label}</span>}
          {showValue && (
            <span>
              {value} / {max}
            </span>
          )}
        </ProgressLabel>
      )}
      <ProgressTrack>
        <ProgressBar 
          $progress={progress} 
          $threshold={threshold}
        />
      </ProgressTrack>
    </ProgressContainer>
  );
};

Progress.propTypes = {
  value: PropTypes.number,
  max: PropTypes.number,
  label: PropTypes.string,
  showValue: PropTypes.bool,
  threshold: PropTypes.number,
  className: PropTypes.string
};

export default Progress; 