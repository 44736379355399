const examplePage = {
    name: 'Example Model',
    description: 'Example Model',
    path: '/example-model',
    layout: 'DefaultLayout',
    template:  'DefaultTemplate',
    sections: [
        {
            name: 'Example Section',
            description: 'Example Section',
            path: '/example-section',
            template:  'ExampleSectionTemplate',
            components: [
                {
                    name: 'Example Component',
                    description: 'Example Component',
                    path: '/example-component',
                    component: 'ExampleComponentPage'
                }
            ]
        }
    ]
}

export default examplePage;