import React from 'react';
import PropTypes from 'prop-types';
import {
  AreaChart as RechartsAreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import ChartContainer from '../../base/ChartContainer';

const AreaChart = ({
  data,
  areas,
  title,
  asymRadius,
  reverse,
  radius,
  height,
  xAxisDataKey = 'name',
  grid = true,
  stacked = false
}) => {
  return (
    <ChartContainer
      title={title}
      asymRadius={asymRadius}
      reverse={reverse}
      radius={radius}
      height={height}
    >
      <ResponsiveContainer width="100%" height="100%">
        <RechartsAreaChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          {grid && (
            <CartesianGrid 
              strokeDasharray="3 3" 
              stroke="rgba(255, 255, 255, 0.1)"
            />
          )}
          <XAxis 
            dataKey={xAxisDataKey} 
            stroke="rgba(255, 255, 255, 0.5)"
          />
          <YAxis 
            stroke="rgba(255, 255, 255, 0.5)"
          />
          <Tooltip 
            contentStyle={{
              background: 'rgba(42, 45, 58, 0.9)',
              border: '1px solid rgba(255, 255, 255, 0.1)',
              borderRadius: '8px',
              color: 'white'
            }}
          />
          {areas.map((area, index) => (
            <Area
              key={area.dataKey}
              type="monotone"
              dataKey={area.dataKey}
              stroke={area.color || `var(--${index === 0 ? 'accent' : 'success'}-colour)`}
              fill={area.color || `var(--${index === 0 ? 'accent' : 'success'}-colour)`}
              fillOpacity={0.2}
              stackId={stacked ? 'stack' : undefined}
            />
          ))}
        </RechartsAreaChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
};

AreaChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  areas: PropTypes.arrayOf(PropTypes.shape({
    dataKey: PropTypes.string.isRequired,
    color: PropTypes.string
  })).isRequired,
  title: PropTypes.string,
  asymRadius: PropTypes.string,
  reverse: PropTypes.bool,
  radius: PropTypes.string,
  height: PropTypes.number,
  xAxisDataKey: PropTypes.string,
  grid: PropTypes.bool,
  stacked: PropTypes.bool
};

export default AreaChart; 