import styled from 'styled-components';
import Box from '../../../../atomic/atoms/Box/Box';

export const TableContainer = styled(Box)`
  width: 100%;
  background: ${({ theme }) => theme?.baseColors?.primary 
    ? `${theme.baseColors.primary}1A` // 1A is 10% opacity in hex
    : 'rgba(42, 45, 58, 0.1)' // fallback color
  };
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  // outline: 0.5px solid var(--accent-colour);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 50px;
  border-top-left-radius: 50px;
  border-top-right-radius: 5px;

  padding: 20px;
  box-shadow: 
    0 4px 24px -1px rgba(0, 0, 0, 0.2),
    0 2px 8px -1px rgba(0, 0, 0, 0.1),
    inset 0 1px 0 0 rgba(255, 255, 255, 0.1);
`;

export const TableWrapper = styled.div`
  position: relative;
  margin: 0.5rem 0;
  overflow: hidden;
`;

export const TableScroller = styled.div`
  overflow-x: auto;
  margin-left: 0;
  margin-right: 0;
  
  /* Custom scrollbar styles */
  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--accent-colour);
    border-radius: 4px;
    
    &:hover {
      background: rgba(255, 255, 255, 0.15);
    }
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
  align-items: flex-end;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const SearchField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-width: 200px;
`;

export const SearchLabel = styled.label`
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  text-align: left;
  min-width: 800px; // Minimum width before scrolling starts
`;

export const Th = styled.th`
  padding: 1rem;
  white-space: nowrap;
  font-weight: 600;
  color: var(--accent-colour);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  cursor: ${props => props.$sortable ? 'pointer' : 'default'};
  user-select: none;
  transition: background 0.2s ease;
  
  &:hover {
    ${props => props.$sortable && `
      background: rgba(255, 255, 255, 0.05);
    `}
  }
`;

export const Td = styled.td`
  padding: 1rem;
  white-space: nowrap;
  color: rgba(255, 255, 255, 0.7);
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
`;

export const Tr = styled.tr`
  cursor: ${props => props.$clickable ? 'pointer' : 'default'};
  transition: all 0.2s ease;

  &:hover {
    ${props => props.$clickable && `
      background: rgba(255, 255, 255, 0.05);
      backdrop-filter: blur(8px);
      -webkit-backdrop-filter: blur(8px);
    `}
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.02);
`;

export const PageInfo = styled.div`
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
`;

export const PageControls = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const ActionButton = styled.button`
  padding: 0.5rem 1rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.9);
  cursor: pointer;
  border-radius: 6px;
  font-weight: 500;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  transition: all 0.2s ease;
  
  &:hover:not(:disabled) {
    background: rgba(255, 255, 255, 0.1);
    transform: translateY(-1px);
  }
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`; 