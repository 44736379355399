import React, {createContext, useContext, useState, useCallback, useEffect, useMemo} from "react";
import {useApiQuery} from "../../../../hooks/customHooks.js";
import {filterPartnersByRoles} from "../utils.js";

const EventContext = createContext();

export const EventProvider = ({ children }) => {
    const [event, setEventState] = useState(null);

    const setEvent = useCallback((newEvent) => {
        setEventState(newEvent);
    }, []);

    const artists = () => {
         return filterPartnersByRoles(event?.partners, ['dj', 'artist']) ?? [];
    }


    return (
        <EventContext.Provider value={{
            setEvent,
            event,
            artists
        }}>
            {children}
        </EventContext.Provider>
    )
}

export const useEvent = () => {
    const context = useContext(EventContext);

    if(!context) {
        throw new Error('EventContext must be used within a EventProvider')
    }


    return context;
}
