import React from 'react';
import UpdateRequestUI from '../../atomic/molecules/UpdateRequestUI/UpdateRequestUI';
import StatCounter from '../atoms/StatCounter/StatCounter';
import styled from 'styled-components';

// Basic Dashboard component that renders sections
const Dashboard = ({ sections, config }) => {
//   if (!sections) return null;

  console.log('config', config);

  return (
    <div>
      {sections.map((section, index) => (
        <div key={index}>
          <h2>{section.name}</h2>
          {section.components?.map((comp, compIndex) => {
            const Component = getComponent(comp.component);
            return Component ? (
              <div key={compIndex}>
                <Component sections={section.components} />
              </div>
            ) : null;
          })}
        </div>
      ))}
    </div>
  );
};

// Component Registry - maps string names to actual components
export const componentRegistry = {
  // Pages
  'Dashboard': Dashboard,

  // Atoms & Molecules
  'UpdateRequestUI': UpdateRequestUI,
  'StatCounter': StatCounter,
  
  // Add more components as needed
};

// Styled components for layouts
const StyledGridLayout = styled.div`
  display: grid;
  grid-template-columns: ${props => `repeat(${props.columns}, 1fr)`};
  gap: 1rem;
`;

const StyledFlexLayout = styled.div`
  display: flex;
  flex-direction: ${props => props.direction};
  gap: 1rem;
`;

// Layout Components Registry
const DefaultLayout = ({ children }) => (
  <div style={{ padding: '2rem' }}>{children}</div>
);

const GridLayout = ({ children, columns = 1 }) => (
  <StyledGridLayout columns={columns}>
    {children}
  </StyledGridLayout>
);

const FlexLayout = ({ children, direction = 'row' }) => (
  <StyledFlexLayout direction={direction}>
    {children}
  </StyledFlexLayout>
);

export const layoutRegistry = {
  'default': DefaultLayout,
  'grid': GridLayout,
  'flex': FlexLayout,
  // Add more layouts as needed
};

// Helper function to get component from registry
export const getComponent = (componentName) => {
  const component = componentRegistry[componentName];
  if (!component) {
    console.warn(`Component ${componentName} not found in registry:`, componentName);
    return null;
  }
  return component;
};

// Helper function to get layout from registry
export const getLayout = (layoutName) => {
  const layout = layoutRegistry[layoutName];
  if (!layout) {
    console.warn(`Layout ${layoutName} not found in registry:`, layoutName);
    return layoutRegistry.default;
  }
  return layout;
}; 