import { css } from 'styled-components';

export const breakpoints = {
  mobile: '768px',
  laptop: '1100px',
  desktop: '1500px',
  largeDesktop: '1850px',
};

export const spacing = {
  xs: '4px',
  sm: '8px',
  md: '16px',
  lg: '24px',
  xl: '32px',
  xxl: '48px',
};

export const borderRadius = {
  sm: '12px',
  md: '20px',
  lg: '32px',
  xl: '40px',
  xxl: '50px',
  // Asymmetric radius for diagonal corners
  asymmetric: {
    sm: '12px 0 12px 0',  // top-left & bottom-right
    md: '20px 0 20px 0',
    lg: '32px 0 32px 0',
    xl: '40px 0 40px 0',
    xxl: '50px 0 50px 0',
    // Reversed (top-right & bottom-left)
    reversed: {
      sm: '0 12px 0 12px',
      md: '0 20px 0 20px',
      lg: '0 32px 0 32px',
      xl: '0 40px 0 40px',
      xxl: '0 50px 0 50px',
    }
  },
  // Full radius for rounded corners
  full: '9999px',
};

export const typography = {
  fonts: {
    primary: 'Helvetica Neue',
    secondary: 'Georgia',
    creative: 'Impact',
  },
  weights: {
    regular: 400,
    medium: 500,
    bold: 700,
    creative: 800,
  },
  sizes: {
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xl: '24px',
    xxl: '32px',
  },
};

// These will integrate with your existing CSS variables
export const baseColors = {
  primary: '#2A2D3A',
  secondary: '#373737',
  accent: '#BFB593',
  text: '#FFFFFF',
  background: '#2A2D3A',
};

export const dimensions = {
  small: '90dvw',
  medium: '450px',
  large: '600px',
  contentWidth: '75vw',
  headerHeight: '60px',
};

// Mixins for common styles
export const mixins = {
  flexCenter: css`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  flexColumn: css`
    display: flex;
    flex-direction: column;
  `,
  absoluteCenter: css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `,
  cardShadow: css`
    box-shadow: 0 2px 4px var(--shadow-colour);
  `,
};

// Media query helpers
export const media = {
  mobile: `@media (max-width: ${breakpoints.mobile})`,
  laptop: `@media (min-width: ${breakpoints.laptop})`,
  desktop: `@media (min-width: ${breakpoints.desktop})`,
  largeDesktop: `@media (min-width: ${breakpoints.largeDesktop})`,
};
