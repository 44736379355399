import { useMemo, useState } from 'react';

export const useTableSearch = (data, columns) => {
  const [searchColumn, setSearchColumn] = useState('');
  const [searchValue, setSearchValue] = useState('');

  const columnTypes = useMemo(() => {
    const types = {};
    columns.forEach(column => {
      if (column.type) {
        types[column.key] = column.type;
      } else {
        const uniqueValues = new Set(data.map(item => item[column.key]));
        types[column.key] = uniqueValues.size <= 5 ? 'enum' : 'text';
      }
    });
    return types;
  }, [columns, data]);

  const columnOptions = useMemo(() => 
    columns.map(column => ({
      value: column.key,
      label: column.label
    }))
  , [columns]);

  const enumOptions = useMemo(() => {
    if (searchColumn && columnTypes[searchColumn] === 'enum') {
      const uniqueValues = [...new Set(data.map(item => item[searchColumn]))];
      return uniqueValues.map(value => ({
        value,
        label: value
      }));
    }
    return [];
  }, [searchColumn, data, columnTypes]);

  const filteredData = useMemo(() => {
    if (!searchColumn || !searchValue) return data;
    
    return data.filter(item => {
      const value = item[searchColumn];
      if (value === null || value === undefined) return false;
      
      if (columnTypes[searchColumn] === 'enum') {
        return value.toLowerCase() === searchValue.toLowerCase();
      }
      
      return value.toString().toLowerCase()
        .includes(searchValue.toLowerCase().trim());
    });
  }, [data, searchColumn, searchValue, columnTypes]);

  return {
    searchColumn,
    setSearchColumn,
    searchValue,
    setSearchValue,
    columnTypes,
    columnOptions,
    enumOptions,
    filteredData
  };
}; 