import React from 'react';
import PropTypes from 'prop-types';
import {
  PaginationContainer,
  PageInfo,
  PageControls,
  ActionButton
} from '../styles/TableStyles';

const TablePagination = ({ 
  currentPage, 
  pageSize, 
  totalItems, 
  onPageChange,
  totalPages
}) => (
  <PaginationContainer>
    <PageInfo>
      Showing {((currentPage - 1) * pageSize) + 1} to {Math.min(currentPage * pageSize, totalItems)} of {totalItems} entries
    </PageInfo>
    <PageControls>
      <ActionButton 
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        Previous
      </ActionButton>
      <ActionButton
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        style={{
          backgroundColor: 'var(--accent-colour)',
          color: 'white'
        }}
      >
        Next
      </ActionButton>
    </PageControls>
  </PaginationContainer>
);

TablePagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired
};

export default TablePagination; 