import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FiChevronDown } from 'react-icons/fi';
import { borderRadius } from '../../../tokens/theme';
import { 
  premiumInteractive, 
  premiumAccent, 
  premiumError,
  premiumAnimation
} from '../../../tokens/animations';

const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const StyledSelect = styled(motion.div)`
  width: 100%;
  height: 48px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: var(--font-weight-generic);
  color: var(--text-colour);
  background: var(--primary-colour);
  border: 1px solid rgba(191, 181, 147, 0.1);
  border-radius: ${props => borderRadius.asymmetric[props.$asymRadius]};
  cursor: pointer;
  user-select: none;
  ${premiumInteractive}

  &:hover:not(:disabled) {
    border-color: rgba(191, 181, 147, 0.3);
    transform: translateY(-1px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }

  ${props => props.$isOpen && css`
    border-color: var(--accent-colour);
    box-shadow: 0 0 0 1px var(--accent-colour-faded),
                0 5px 15px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
  `}

  ${props => props.$error && css`
    border-color: var(--error-colour);
    &:focus {
      border-color: var(--error-colour);
      box-shadow: 0 0 0 1px var(--error-colour),
                  0 5px 15px rgba(181, 89, 89, 0.2);
    }
  `}

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    background: var(--primary-colour);
    border-color: rgba(191, 181, 147, 0.05);
    transform: none;
    box-shadow: none;
  }
`;

const SelectedValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

const Placeholder = styled.span`
  opacity: 0.5;
`;

const ChevronIcon = styled(motion.div)`
  display: flex;
  align-items: center;
  color: var(--text-colour);
  ${premiumAnimation}

  ${props => props.$isOpen && css`
    transform: rotate(180deg);
    ${premiumAccent}
  `}
`;

const OptionsContainer = styled(motion.div)`
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  width: 100%;
  background: var(--primary-colour);
  border: 1px solid rgba(191, 181, 147, 0.1);
  border-radius: ${props => borderRadius.asymmetric[props.$asymRadius]};
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  z-index: 100;
  overflow: hidden;
`;

const OptionsList = styled(motion.div)`
  max-height: 200px;
  overflow-y: auto;
  color: var(--text-colour);

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(191, 181, 147, 0.1);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(191, 181, 147, 0.2);
    border-radius: 4px;
    
    &:hover {
      background: rgba(191, 181, 147, 0.3);
    }
  }
`;

const Option = styled(motion.div)`
  padding: 12px 20px;
  cursor: pointer;
  ${premiumInteractive}

  ${props => props.$isSelected && css`
    ${premiumAccent}
    background: rgba(191, 181, 147, 0.1);
  `}

  &:hover {
    background: rgba(191, 181, 147, 0.1);
  }
`;

const ErrorMessage = styled(motion.div)`
  color: var(--error-colour);
  font-size: 12px;
  margin-top: 6px;
  display: flex;
  align-items: center;
  gap: 4px;
  opacity: 0.9;
`;

const Select = forwardRef(({
  options = [],
  value,
  onChange,
  placeholder = 'Select an option',
  error = false,
  errorMessage,
  asymRadius = 'sm',
  disabled = false,
  className,
  ...props
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectedOption = options.find(option => option.value === value);

  const handleSelect = (option) => {
    onChange(option.value);
    setIsOpen(false);
  };

  const dropdownVariants = {
    hidden: { 
      opacity: 0,
      y: -10,
      scale: 0.95,
    },
    visible: { 
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        duration: 0.2,
        ease: 'easeOut'
      }
    },
    exit: { 
      opacity: 0,
      y: -10,
      scale: 0.95,
      transition: {
        duration: 0.2,
        ease: 'easeIn'
      }
    }
  };

  const optionVariants = {
    hidden: { 
      opacity: 0,
      x: -20
    },
    visible: { 
      opacity: 1,
      x: 0
    }
  };

  return (
    <SelectWrapper className={className} ref={ref}>
      <StyledSelect
        $isOpen={isOpen}
        $error={error}
        $asymRadius={asymRadius}
        onClick={() => !disabled && setIsOpen(!isOpen)}
        disabled={disabled}
        {...props}
      >
        <SelectedValue>
          {selectedOption ? (
            selectedOption.label
          ) : (
            <Placeholder>{placeholder}</Placeholder>
          )}
          <ChevronIcon $isOpen={isOpen}>
            <FiChevronDown size={20} />
          </ChevronIcon>
        </SelectedValue>
      </StyledSelect>

      <AnimatePresence>
        {isOpen && !disabled && (
          <OptionsContainer
            variants={dropdownVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            $asymRadius={asymRadius}
          >
            <OptionsList>
              {options.map((option, index) => (
                <Option
                  key={option.value}
                  onClick={() => handleSelect(option)}
                  $isSelected={option.value === value}
                  variants={optionVariants}
                  initial="hidden"
                  animate="visible"
                  transition={{
                    delay: index * 0.05,
                    duration: 0.2
                  }}
                >
                  {option.label}
                </Option>
              ))}
            </OptionsList>
          </OptionsContainer>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {error && errorMessage && (
          <ErrorMessage
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
          >
            {errorMessage}
          </ErrorMessage>
        )}
      </AnimatePresence>
    </SelectWrapper>
  );
});

Select.displayName = 'Select';

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.node.isRequired,
    })
  ),
  value: PropTypes.any,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  asymRadius: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default Select;
